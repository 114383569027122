<template>
    <div class="d-flex flex-column align-center">
      <v-col cols="12">
        <!-- <h1 class="text-center">{{$t('address')}}</h1> -->
      </v-col>
      <div class="" style="width: 80%">

          <v-row >
            <v-col cols="12" md="3">
              <!-- <label>Email</label> -->
              <v-text-field
                v-mask="'#####-###'"
                v-model="titularUser.cep"
                class="rounded-lg"
                hide-details="auto"
                label="CEP"
                @blur="fetchAddress"
                outlined
                dense
                :disabled="!editing"
              ></v-text-field>
            </v-col>
            </v-row>
            <v-row>
            <v-col cols="8" md="6">
              <v-text-field
                class="rounded-lg"
                :label="$t('street')"
                hide-details="auto"
                v-model="titularUser.logradouro"
                outlined
                dense
                :disabled="!editing"
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="2">
              <v-text-field
                class="rounded-lg"
                :label="$t('number')"
                hide-details="auto"
                v-model="titularUser.numero"
                outlined
                dense
                :disabled="!editing"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                class="rounded-lg"
                :label="$t('complement')"
                hide-details="auto"
                v-model="complemento"
                outlined
                dense
                :disabled="!editing"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                class="rounded-lg"
                :label="$t('neighborhood')"
                hide-details="auto"
                v-model="titularUser.bairro"
                outlined
                dense
                :disabled="!editing"
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="4">
              <!-- <label>Email</label> -->
              <v-text-field
                v-model="titularUser.cidade"
                class="rounded-lg"
                hide-details="auto"
                :label="$t('city')"
                outlined
                dense
                :disabled="!editing"
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="2">
              <!-- <label>Email</label> -->
              <v-text-field
                v-model="titularUser.uf"
                class="rounded-lg"
                hide-details="auto"
                :label="$t('state')"
                outlined
                dense
                :disabled="!editing"
              ></v-text-field>
            </v-col>
          </v-row>
      </div>
      <v-col cols="12" class="d-flex justify-center">
        <v-hover v-slot="{ hover }">
          <v-btn
            :class="{ 'on-hover': hover }"
            class="transition-fast-in-fast-out mr-2"
            :color=" editing ? 'white' : 'button'"
            @click="editing = !editing"
            :dark="!editing"
            rounded
          >
            {{ editing ? 'Cancelar' : 'Editar' }}
          </v-btn>
        </v-hover>
        <v-hover v-slot="{ hover }" v-if="editing">
          <v-btn
            :class="{ 'on-hover': hover }"
            class="transition-fast-in-fast-out"
            color="primary"
            @click="updateTitular"
            rounded
          >
            <span>{{$t('confirm')}}</span>
          </v-btn>
        </v-hover>
      </v-col>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  export default {
    data() {
      return {
        complemento: null,
        userId: localStorage.getItem("userId"),
        titularUser: {},
        password: null,
        showPass1: false,
        confirmPass1: false,
        token: localStorage.getItem("token"),
        urlEnv: process.env.VUE_APP_SERVICE_URL,
        editing: false,
        user: {
          name: null,
          email: null,
          phone_number: null,
          profile_photo_url: null,
        },
        rules: [
          (v) => !!v || "Campo obrigatório",
          (v) =>
            (v && v.length >= 8) || "A senha deve ter pelo menos 8 caracteres",
          (v) =>
            (v && /[A-Z]/.test(v)) ||
            "A senha deve conter pelo menos uma letra maiúscula",
          (v) =>
            (v && /[a-z]/.test(v)) ||
            "A senha deve conter pelo menos uma letra minúscula",
          (v) =>
            (v && /\d/.test(v)) || "A senha deve conter pelo menos um número",
        ],
        rulesEmail: [(v) => this.validateEmail(v) || "E-mail inválido"],
      };
    },
    methods: {
      validateEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      },
      async getUserData() {
        const config = {
          headers: { Authorization: `Bearer ${this.token}` },
        };
        try {
          const response = await axios.get(this.urlEnv + "user", config);
          const userData = response.data;
          this.user.name = userData.name;
          this.user.email = userData.email;
          this.user.phone_number = userData.phone_number;
          this.user.profile_photo_url = userData.profile_photo_url;
        } catch (error) {
          console.error(error);
        }
      },
      async getMeusDados() {
      const config = {
        headers: { Authorization: `Bearer ${this.token}` },
      };
      try {
        axios
          .get(this.urlEnv + `getMeusDados`, config)
          .then((response) => {
            this.titularUser = response.data.data.endereco
            console.log(this.titularUser)
          })
          .catch((error) => {
            console.error(error);
          });
      } catch(err) {
        console.log(err)
      }
    },
    async fetchAddress() {
      try {
        const cep = this.titularUser.cep.replace(/\D/g, ""); 
        const response = await axios.get(
          `https://viacep.com.br/ws/${cep}/json/`
        );
        const addressData = response.data;
        this.titularUser.logradouro = addressData.logradouro;
        this.titularUser.bairro = addressData.bairro;
        this.titularUser.cidade = addressData.localidade;
        this.titularUser.uf = addressData.uf;
        this.pais = "Brasil";
      } catch (error) {
        console.error(error);
      }
    },
    updateTitular() {
      const config = {
        headers: { Authorization: `Bearer ${this.token}` },
      };
      const data = {
        Cep: this.titularUser.cep.replace(/\D/g, ''),
        UF: this.titularUser.uf,
        Cidade: this.titularUser.cidade,
        Bairro: this.titularUser.bairro,
        Numero: this.titularUser.numero,
        Logradouro: this.titularUser.logradouro,
      };

      try {
        axios
          .post(this.urlEnv + "updateInvestidor", data, config)
          .then((response) => {
            this.$toast.success('Titular Atualizado');
            this.editing = !this.editing;
            console.log(response.data);
          })
          .catch((err) => {
            console.log(err)
          });
      } catch (error) {
        console.log(error);
      }
    },
    applyMaskToCep() {
      const cep = this.titularUser.cep;
      if (cep) {
        this.titularUser.cep = this.formatCep(cep);
      }
    },
    formatCep(cep) {
      return cep.replace(/^(\d{5})(\d{3})$/, "$1-$2");
    },
    },
    mounted() {
      this.getUserData();
      this.getMeusDados()
      this.applyMaskToCep();
    },
    watch: {
    "titularUser.cep": function (newCep, oldCep) {
      if (newCep !== oldCep) {
        this.applyMaskToCep();
      }
    },
  },
  };
  </script>
  