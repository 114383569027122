import layout from "../../layouts/internalLayout.vue";
import TemplateRegister from "./TemplateRegister.vue";
// import activateQuestions from "./Pages/activateQuestions.vue"

const routes = {
  component: layout,
  children: [
    {
      name: "templateRegister",
      path: "TemplateRegister",
      component: TemplateRegister,
      meta: {
        requiresAuth: true,
      },
    },
    // {
    //   name: "questions",
    //   path: "questions",
    //   component: activateQuestions,
    //   meta: {
    //     requiresAuth: true,
    //   },
    // },
  ],
};

export default routes;
