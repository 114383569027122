<template>
    <v-main style="background: #00265b;  overflow-x:hidden">
       
            <!-- <v-row>
                <v-col cols="12" md="12">
                  <v-img max-width="300" src="../../../assets/belat.png"></v-img>
                </v-col>
              </v-row> -->

            <div class="d-flex flex-column items-center white" style="width: 100%">
                <div class="px-6 py-5 d-flex align-center"
                    style="border-bottom: 1px solid #14aa95; justify-content: center">
                    <div class="d-flex">
                        <div class="text-h5 font-weight-medium text-center primary--text "
                            style="display: flex; align-items: center; font-family: 'Raleway'">
                            Declarações
                        </div>
                    </div>
                </div>
            </div>
            <div class="adjustScreenFinish mx-5 mt-10 mt-xl-24 mt-lg-15">
                <v-col :style="{
            padding: currentScreenIndex === 0 ? '7px' : 'auto',
            width: currentScreenIndex === 0 ? '700px' : 'auto',
            display: currentScreenIndex === 0 ? 'grid' : 'block',
            justifyItems: currentScreenIndex === 0 ? 'center' : 'initial',
            alignItems: currentScreenIndex === 0 ? 'center' : 'initial'
        }">
                    <div v-for="question, questionId in stages[currentStage].questions[currentScreenIndex]"
                        :key="questionId">
                        <h3 v-if="question.extra_title">{{ question.extra_title }}</h3>
                    </div>


                    <v-row>
                        <v-col class=""
                            v-for="(question, questionIndex) in stages[currentStage].questions[currentScreenIndex]"
                            :key="questionIndex" cols="12">
                            <h2
                                class="white--text font-weight-light px-lg-14 px-xl-12" style="font-size:20px">
                                {{ question.text }}
                                <v-tooltip max-width="350" bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon color="white" dark size="15" v-bind="attrs" v-on="on">
                                            mdi-information-outline
                                        </v-icon>
                                    </template>
                                    <span>{{ question.tooltip }}</span>
                                </v-tooltip>
                            </h2>
                            <!-- {{ question.answer }} -->
                            <div class="mt-2">
                                <label>
                                    <input type="radio" v-model="question.answer" value="Sim" />
                                    <span class="white--text">
                                        Sim
                                    </span>
                                  </label>
                                  <label class="ml-10">
                                    <input type="radio" v-model="question.answer" value="Não" />
                                    <span class="white--text">
                                    Não
                                    </span>
                                  </label>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </div>
            <v-col cols="12">
                <div class="d-flex justify-center mt-xl-24 mt-lg-10 mt-5">
                    <v-btn dark style="border: 1px solid #14AA95 !important" color="primary" class="mr-5 px-16 white--text"
                        v-if="currentQuestionIndex == 0" rounded @click="backPage">Voltar</v-btn>
                    <v-btn style="border: 1px solid #00265b" class="mr-5 px-16 primary--text"
                        v-if="currentScreenIndex > 0" rounded @click="currentScreenIndex--">Voltar</v-btn>
                    <v-btn class="px-10" dark rounded color="secondary"
                        @click="currentScreenIndex < stages[currentScreenIndex].questions.length - 1 ? currentScreenIndex++ : finishStage()">
                        {{ currentScreenIndex < stages[currentScreenIndex].questions.length - 1 ? 'Avançar'
            : 'Finalizar' }} </v-btn>
                </div>
            </v-col>
    </v-main>
</template>

<script>

import axios from 'axios';
export default {
    props: {
        currentTimeline: Array,
        currentPosition: Number,
    },

    data() {
        return {
            activeIndex: 0,
            completedIndexes: [0, 1, 2],
            token: localStorage.getItem("token"),
            urlEnv: process.env.VUE_APP_SERVICE_URL,
            currentQuestionIndex: 0,
            answers: {},
            currentScreenIndex: 0,
            currentStage: 0,
            answering: false,
            stages: [
                {
                    position: 1,
                    title: 'Declarações',
                    text: 'Última etapa para poder investir',
                    questions: [
                        [
                            {
                                id: 'exposta',
                                type: 'radio',
                                text: this.$t('question4statements'),
                                tooltip: this.$t('option1question4statements'),
                                options: [],
                            },
                            // {
                            //     id: 'residencia',
                            //     type: 'radio',
                            //     text: this.$t('question6statements'),
                            //     tooltip: this.$t('option1question6statements'),

                            //     options: [],
                            // },
                            // {
                            //     id: 'AML',
                            //     type: 'radio',
                            //     text: this.$t('question8statements'),
                            //     tooltip: this.$t('option1question8statements'),
                            //     answer: true,
                            //     options: [
                            //     ],
                            // },
                            {
                                id: 'qualificado',
                                type: 'radio',
                                text: this.$t('question5statements'),
                                tooltip: this.$t('option1question5statements'),

                                options: [],
                            },
                            // {
                            //     id: 'FATCA',
                            //     type: 'radio',
                            //     text: this.$t('question7statements'),
                            //     tooltip: this.$t('option1question7statements'),
                            //     options: [],
                            // },
                        ],
                    ],
                },
            ]
        }
    },
    mounted() {
        this.getMeusDados()
        console.log(this.currentScreenIndex)
    },
    methods: {
        backPage() {
            this.$parent.goToPreviousScreen();
        },
        async getMeusDados() {
            const config = {
                headers: { Authorization: `Bearer ${this.token}` },
            };
            try {
                axios
                    .get(this.urlEnv + `getQuestions`, config)
                    .then((response) => {

                        this.stages[this.currentStage].questions.forEach(questionGroup => {
                            questionGroup.forEach(question => {
                                const answer = response.data.data.questions[question.id]?.answer;
                                if (answer) {
                                    question.answer = answer;
                                    console.log(question)
                                    this.$nextTick(() => {
                                        this.$forceUpdate();
                                    });
                                }
                            });
                        });
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            } catch (err) {
                console.log(err);
            }
        },
        // handleButtonClick() {
        //     if (this.currentQuestionIndex < this.stages[this.currentScreenIndex].questions.length - 1) {
        //         this.currentQuestionIndex++;
        //     } else {
        //         this.finishStage();
        //     }
        // },
        finishStage() {
            const config = {
                headers: { Authorization: `Bearer ${this.token}` },
            };
            const data = {
                questions: this.stages[this.currentStage].questions,
                finished: true,
            };
            console.log(data)
            try {
                axios
                    .post(this.urlEnv + "saveQuestions", data, config)
                    .then((response) => {
                        console.log(response.data);
                        this.$emit('incrementPosition')
                        this.$parent.goToNextScreen();
                    })
                    .catch((error) => {
                        this.$toast.error(error);
                    });
            } catch (error) {
                console.log(error);
            }
        },
        getColor(position) {
            return position <= this.currentPosition ? 'secondary' : 'gray'; // Retorna 'secondary' se a posição for menor ou igual a currentScreenIndex
        },
    },

}
</script>

<style scoped>
.text-h4,
.text-xl-h4,
.text-xl-body-2,
.text-h3,
.text-h5 .tex px-xl-12t-h6,
.white--text,
.primary--text,
.text-center,
.d-flex {
    font-family: 'Raleway', cursive !important;
}

.custom-container {
    /* max-width: 1440px; */
    margin: 0 auto;
    border: 1px solid #fff;
    padding: 20px 0;
}

.stage-indicator {
    display: inline-block;
    width: 100px;
    /* Ajuste conforme necessário */
    padding: 5px;
    margin: 0 10px;
    /* Espaçamento entre as etapas */
    border: 1px solid #ccc;
    border-radius: 5px;
}

.v-timeline::before {
    transform: rotate(90deg) !important;
}

.v-application--is-ltr .v-timeline--dense:not(.v-timeline--reverse)::before {
    left: 0 !important
}

.adjustScreenFinish {
    height: 70vh;
}
</style>