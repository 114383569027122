<template>
  <layout :click-busi="false">
    <template v-slot:content>
      <v-container style="border: 0px" class="px-5 mt-5 custom-container" fluid>
        <v-dialog max-width="500" v-model="dialog">
          <SimulationInvest @close="close" :negocio="negocio" :titularUser="titularUser"/>
        </v-dialog>
        <v-row class="w-100" max-width="100%">
          <v-col cols="12" md="3" class="mr-0 mb-0 pb-0 pr-0">
            <v-card outlined style="border: 1px solid rgba(20, 170, 149, 0.5)" class="mx-auto pa-1" max-width="300">
              <div class="pb-2">
                <!-- {{ negocio.fotos }} -->
                <v-img height="120" class="align-end text-white" v-bind:src="getPhoto(negocio?.logo)">

                </v-img>
                <!-- {{ negocio.fotos[0].content }} -->
                <v-card-text>
                  <v-progress-linear background-color="grey lighten-3" :color="getProgressBarColor(negocio.neg_area_id)"
                    :value="calcPercentInvestiment(negocio)" height="15" rounded
                    :style="{ fontSize: '10px', borderRadius: '8px', border: '1px solid #ccc' }">
                    <template v-slot:default="{ value }">
                      <span :class="{ 'white--text': value > 50 }" >
                        {{ Math.ceil(value) }}%
                      </span>
                    </template>
                  </v-progress-linear>
                  <div class="text-center mt-2">
                    <span class="secondary--text mt-xl-1 text-caption text-uppercase font-weight-bold"
                      style="font-size: 10px !important;">Valor total do empréstimo</span>
                  </div>
                  <v-col class="ml-1 mr-2 pa-0 grey lighten-3 rounded text-center">
                    <span class="primary--text font-weight-light font-aleo " style="font-size: 16px; padding-top: 0;">
                      {{ numberToReal(Math.floor(parseFloat(negocio.valor_a_ser_captado)/ 1000) * 1000) }}
                    </span>
                  </v-col>
                  <v-row class="pb-0 px-1 pt-3">
                    <v-col cols="6" class="py-0 px-2">
                      <div class="secondary--text text-caption text-uppercase font-weight-bold text-center mt-2"
                        style="font-size: 9px !important;">
                        Taxa de retorno
                        <v-tooltip max-width="350" bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon color="primary" dark size="15" v-bind="attrs" v-on="on">
                                            mdi-information-outline
                                        </v-icon>
                                    </template>
                                    <span>A Taxa de Juros acima é igual ao seu Retorno Mensal Bruto (antes do desconto de IR, custos de transferência e potencial inadimplência).</span>
                                </v-tooltip>
                      </div>

                    </v-col>
                    <v-col cols="6" class="py-0 px-2">
                      <div class="secondary--text text-caption text-uppercase font-weight-bold text-center mt-2"
                        style="font-size: 9px !important;">
                        Prazo de operação
                      </div>

                    </v-col>
                  </v-row>
                  <v-row class="pa-0">
                    <v-col class="mr-1 ml-2 pa-0 grey lighten-3 rounded d-flex align-center text-center justify-center">
                      <span class="primary--text font-weight-light font-aleo "
                        style="font-size: 16px; padding-top: 0;">{{
    numberToPercentage(parseFloat(negocio.taxa)) }} <br>
                        <span class="texto2 primary--text font-weight-medium" style="padding: 0 !important;">NOMINAL
                          MENSAL</span>
                      </span>

                    </v-col>
                    <v-col class="ml-1 mr-2 pa-0 grey lighten-3 rounded d-flex align-center text-center justify-center">
                      <span class="primary--text align-center font-weight-light font-aleo"
                        style="font-size: 16px; padding-top: 0;">{{ negocio.prazo }} MESES</span>
                      <!--<v-card-text class="texto2 red--text font-weight-medium pb-xl-2 text-center" style="padding: 0">RENOVABLE</v-card-text>-->
                    </v-col>
                  </v-row>
                  <!-- <v-row>
                    <v-col> {{ business.numero_parcelas }} {{ $t('installments') }} </v-col>
                    <v-col class="text-right">
                      {{ business.taxa }}% a.m.
                    </v-col>
                  </v-row> -->
                  <div class="text-center mt-4">
                    <span class="secondary--text mt-xl-1 text-caption text-uppercase font-weight-bold"
                      style="font-size: 10px !important;">Investimento Mínimo</span>
                  </div>
                  <div class="grey lighten-3 rounded align-center text-center" style="padding-top: 0">
                    <span class="primary--text font-weight-light font-aleo" style="font-size: 16px; padding-top: 0;">{{
    numberToReal(parseFloat(negocio.ticket_minimo)) }}</span>
                  </div>

                  <v-hover v-slot="{ hover }">
                    <v-btn rounded style="width: 100%" :class="{ 'on-hover': hover }"
                      class="transition-fast-in-fast-out mt-3" :color="hover ? 'secondary' : 'primary'"
                      @click="dialog = true" :disabled="cantInvest"
                      >{{ $t('simulate_offer') }}</v-btn>
                  </v-hover>

                  <div class="mt-3 px-0 bx-0">
                    <span class="porcentagem primary--text" style="font-size: 10px !important;">
                      IMPACTO SOCIOAMBIENTAL DECLARADO:
                    </span>
                    <p class="porcentagem primary--text" style="font-size: 10px !important;">
                      {{ negocio.impacto_socioambiental?.length > 80 ? negocio.impacto_socioambiental.substring(0, 80) + '...' : negocio.impacto_socioambiental }}
                    </p>
                  </div>
                  <v-row class="px-3">
                    <v-col cols="6" style="padding: 0px;">
                      <div class="d-flex" height="150" style="padding: 5px;">
                        <v-img width="80" :src="negocio.area?.imagem.content"></v-img>
                      </div>
                    </v-col>
                    <v-col cols="6" style="padding: 0px;">
                      <div class="d-flex" height="150" style="padding: 5px;">
                        <v-img width="90" :src="negocio.setor?.imagem.content"></v-img>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </div>

            </v-card>
          </v-col>
          <v-col cols="12" md="9" class="ml-0 bl-0">
            <v-tabs color="secondary" v-model="tab">
              <v-tab class="text-start no-horizontal-align">Dados <br> da empresa</v-tab>
              <v-tab class="text-start no-horizontal-align">Impacto <br> Positivo</v-tab>
              <v-tab class="text-start no-horizontal-align">Dados <br> Financeiros</v-tab>
              <v-tab class="text-start no-horizontal-align">Dados de <br> operação</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab" style="overflow-y: hidden;overflow-x: hidden;">
              <v-tab-item >
                <v-card flat >
                  <v-card-text>
                    <v-row class="mt-2">
                      <v-col cols="6">
                        <span class="mt-xl-2 primary--text text-overline">
                          {{ $t('who') }} {{ $t('is') }}
                        </span>
                        <p class="mt-2 primary--text" style="font-size: 14px !important;"> {{ negocio.quem_e }}</p>
                      </v-col>
                      <v-col cols="6">
                        <span class="mt-xl-2 primary--text text-overline">
                          Quem faz
                        </span>
                        <p class="mt-2 primary--text" style="font-size: 14px !important;">{{ negocio.quem_faz }}
                        </p>
                        <p v-if="negocio.foto_destaque">
                          <v-img :src="negocio.foto_destaque.content" min-width="300"  max-width="384" max-height="226" contain></v-img>
                        </p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="primary--text" style="font-size: 14px !important;">
                        <b>Razão Social:</b> {{ negocio.razao_social }}
                        <br><b>CNPJ:</b> {{ negocio?.cnpj?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')  }}
                        <br><b>Endereço:</b> {{ negocio.endereco }}
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-text class="py-0">
                    <v-row class="mt-2">
                      <v-col cols="6" >
                        <span class="mt-xl-2 primary--text text-overline">
                          {{ $t('impact') }}
                        </span>
                        <p class="mt-2 primary--text">{{ negocio.impacto }}</p>
                        <span class="mt-xl-2 primary--text text-overline">
                          Boas Práticas
                        </span>
                        <p class="mt-2 primary--text">{{ negocio.boas_praticas }}</p>
                      </v-col>
                      <v-col cols="6" >
                        <span class="mt-xl-2 primary--text text-overline">
                          {{ $t('we_support') }}
                        </span>
                        <p class="mt-2 primary--text">{{ negocio.pq_apoiamos }}</p>
                        <v-row>
                          <v-col class="ma-0 pa-1" cols="3" v-for="ods in negocio.ods" :key="ods.id">
                          <v-img :src="ods.imagem.content"></v-img>
                        </v-col>
                      </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-row class="mt-2">
                      <v-col cols="6">
                        <h1 class="primary--text text-body-1"><b>Estruturação de crédito:</b>{{negocio?.estruturacao_credito ?? ''}}</h1>
                        <v-card elevation="0" color="primary" tile class="mt-3 px-4 py-2">
                          <span class="text-body-1 white--text font-weight-bold">GARANTIAS</span>
                          <p class="text-caption font-weight-light white--text mt-5">{{ negocio.garantias }}</p>
                        </v-card>
                        <h1 class="ml-2 mt-3 text-body-1 primary--text text-uppercase font-weight-bold">Opinião de
                          credito</h1>
                        <p class="ml-2 text-caption primary--text mt-2 font-weight-light">{{ negocio.opiniao_credito }}
                        </p>
                      </v-col>
                      <v-col cols="6">
                        <div v-for="(image, index) in negocio.grafico?.slice(0,2)" :key="index" class="image-container" >
    <v-img 
      class="mt-3 image"
      min-width="300" contain max-width="350px"
      :src="image.content"
      @click="openImageModal(image.content)"
      style="cursor: zoom-in;"
    >   <v-icon
      class="lupa-icon"
      color="grey"
      @click="openImageModal(image.content)"
      style="position: relative; top: 50%; left: 50%; transform: translate(-50%, -50%); font-size: 48px; cursor: zoom-in;"
        
    >
      mdi-magnify
    </v-icon></v-img>
 
  </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-row class="mt-5">
                      <v-col cols="12">
                        <v-data-table  hide-default-header
                          hide-default-footer :headers="headers" :items="desserts" item-key="name"
                          class="elevation-1">
                        </v-data-table>
                        <div class="mt-4">
                          <span>*O período de reserva de investimento é o momento onde os investidores efetuam a solicitação do valor que querem investir. Ao chegar em 100% do valor total solicitado pela empresa, o período de reserva de investimento é encerrado imediatamente.</span>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>

          </v-col>
        </v-row>
        <fullscreen-image-modal
          :dialog="isImageModalOpen"
          :imageSrc="selectedImage"
          @update:dialog="isImageModalOpen = $event"
        ></fullscreen-image-modal>
      </v-container>
    </template>
  </layout>
</template>

<script>
import axios from "axios";
import layout from "../../layouts/internalLayout.vue";
import SimulationInvest from "./Modal/SimulationInvest.vue";
import { calcDias, numberToReal, getPhoto, numberToPercentage} from "../../js/belatUtils";
import FullscreenImageModal from './Components/FullscreenImageModal.vue';
const gradients = [["#222"]];
export default {
  metaInfo() {
    return {
      title: 'BELat - Negócio'
    }
  },
  components: {
    SimulationInvest,
    layout,
    FullscreenImageModal
  },
  data() {
    return {
      isImageModalOpen: false,
      selectedImage: '',
      tab: null,
      userId: localStorage.getItem("userId"),
      selectedSection: "impacto",
      dialog: false,
      width: 2,
      padding: 8,
      lineCap: "round",
      gradient: gradients[0],
      value: [0, 8, 1, 12],
      gradientDirection: "top",
      fill: false,
      type: "trend",
      negocio: {},
      capital: {},
      autoLineWidth: false,
      token: localStorage.getItem("token"),
      urlEnv: process.env.VUE_APP_SERVICE_URL,
      businesses: [],
      headers: [
        {
          text: 'Dessert (100g serving)',
          align: 'start',
          value: 'name',
        },
        { text: 'Category', value: 'category' },
      ],
      desserts: [
        {
          name: 'Valor Total',
          category: '',
        },
        {
          name: 'Número de parcelas',
          category: '',
        },
        {
          name: 'Taxa de rentabilidade',
          category: '',
        },
        {
          name: 'Aporte minimo',
          category: '',
        },
        {
          name: 'Garantias',
          category: '',
        },
        {
          name: 'Minimo de captação da oportunidade',
          category: '',
        },
        {
          name: 'Data limite para reserva',
          category: '',
        },
        {
          name: 'Data prevista para transferência dos recursos',
          category: '',
        },
      ],
      titularUser: {},
      items: [
        {
          title: "Meus Investimentos",
          icon: "mdi-home-city",
          route: "/user/MyInvestiments",
        },
        { title: "Meus dados", icon: "mdi-account", route: "/user/MyInfo" },
      ],
    };
  },

  methods: {
    openImageModal(imageSrc) {
      this.selectedImage = imageSrc;
      this.isImageModalOpen = true;
    },
    formatMoney(value) {
      return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
    },
    formatDate(value) {
      console.log(value);
      const [year, month, day] = value.split('-');
      return day+"/"+month+"/"+year;
    },
    formatProfitability(value) {
      return `${value.replace('.', ',')}% a.m.`;
    },
    getProgressBarColor(negAreaId) {
      switch (negAreaId) {
        case 1:
          return 'desenvolvimento_social';
        case 2:
          return 'educacao_e_cultura';
        case 3:
          return 'meio_ambiente';
        default:
          return 'black';
      }
    },
    calcPercentInvestiment(business) {
      if(business.valor_a_ser_captado && business.valor_ja_contratado)
        return 100*(business.valor_ja_contratado / business.valor_a_ser_captado);
      else return 0;
    },
    async getInvestidor() {
      const config = {
        headers: { Authorization: `Bearer ${this.token}` },
      };
      try {
        axios
          .get(this.urlEnv + `getInvestidor`, config)
          .then((response) => {
            this.titularUser = response.data.data.investidor;
          })
          .catch((error) => {
            console.error(error);
          });
      } catch (err) {
        console.log(err);
      }
    },
    close() {
      this.dialog = false;
    },
    getBusinessDetail(businessId) {
      const config = {
        headers: { Authorization: `Bearer ${this.token}` },
      };

      return axios.get(this.urlEnv + `businessDetail/${businessId}`, config)
        .then((response) => {
          this.negocio = response.data.data.negocio;
          this.capital = response.data.data.negocio.titular.pessoa_juridica;
          const translate = {
            'Valor Total': 'valor',
            'Número de parcelas': 'numero_parcelas',
            'Taxa de rentabilidade': 'taxa',
            'Aporte minimo': 'ticket_minimo',
            'Garantias': 'garantias',
            'Minimo de captação da oportunidade': 'valor_minimo',
            'Data limite para reserva': 'data_limite',
            'Data prevista para transferência dos recursos': 'prev_desembolso'
          };
          this.desserts.forEach(dessert => {
            const value = translate[dessert.name];
            if (value) {
              if (['valor', 'ticket_minimo', 'valor_minimo'].includes(value)) {
                dessert.category = this.formatMoney(response.data.data.negocio[value]);
              }
              else if (['data_limite', 'prev_desembolso'].includes(value) && response.data.data.negocio[value]) {
                dessert.category = this.formatDate(response.data.data.negocio[value]);
              }
              else if (['taxa'].includes(value)){
                dessert.category = this.formatProfitability(response.data.data.negocio[value]);
              }
              else {
                dessert.category = response.data.data.negocio[value];
              }
            } else {
              dessert.category = '';
            }
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    isDateExceeded(limitDate) {
      if(!limitDate){
        return true;
      }
      const limitDateObj = new Date(limitDate);
      const today = new Date();
      return today > limitDateObj;
    }
  },
  computed: {
    cantInvest(){
      return parseFloat(this.negocio.valor_ja_contratado) >= parseFloat(this.negocio.valor) 
        || this.negocio.status?.codigo != '02' 
        || this.isDateExceeded(this.negocio?.data_limite);
    },
    formattedCNPJ() {
      const cnpj = this.negocio.cnpj_cpf;
      if (cnpj) {
        const numericCNPJ = cnpj.replace(/\D/g, "");
        const cnpjMask = "##.###.###/####-##";
        let formattedCNPJ = "";
        let index = 0;
        for (let i = 0; i < cnpjMask.length; i++) {
          if (cnpjMask[i] === "#") {
            formattedCNPJ += numericCNPJ[index] || "";
            index++;
          } else {
            formattedCNPJ += cnpjMask[i];
          }
        }

        return formattedCNPJ;
      }

      return cnpj;
    },
    calcDias() {
      return calcDias;
    },
    numberToReal() {
      return numberToReal;
    },
    getPhoto() {
      return getPhoto;
    },
    numberToPercentage() {
      return numberToPercentage;
    },
  },
  mounted() {
    const businessId = this.$route.params.businessId;
    this.getBusinessDetail(businessId);
    this.getInvestidor();
    window.scrollTo(0, 0);
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Aleo&display=swap');
.no-horizontal-align{
  justify-content: initial !important;
}
.font-aleo {
  font-family: 'Aleo', sans-serif !important;
}

.text-h4,
.text-xl-h4,
.text-xl-h5,
.text-h3,
.text-h5 .text-h6,
.white--text,
.text-center,
.secondary--text,
.d-flex {
  font-family: 'Raleway', cursive !important;
}

.custom-container {
  max-width: auto;
  margin: 0 auto;
  border: 1px solid #fff;
  padding: 0 10rem 0 10rem;
}

.v-window__next,
.v-window__prev {
  position: absolute;
  top: 20vh;
}

.image-container {
  display: inline-block;
}

.image-container .image {
  transition: opacity 0.3s;
}

.image-container:hover .image {
  opacity: 0.5;
}

.lupa-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 48px;
  opacity: 0;
  transition: opacity 0.3s;
}

.image-container:hover .lupa-icon {
  opacity: 1;
}
@media (min-width: 2560px) {}

@media (min-width: 1920px) and (max-width: 2559px) {
  .texto {
    font-size: 0.62rem !important;
  }

  .texto2 {
    font-size: 0.59rem !important;
  }

  .porcentagem {
    font-size: 1.2rem !important
  }

  .mensal {
    font-size: 0.873rem !important
  }
}

@media (min-width: 1280px) and (max-width: 1919px) {

  .texto2 {
    font-size: .57rem !important;
  }
}

@media (max-width: 1279px) {}

.v-tab {
  font-family: 'Raleway', cursive !important;
  text-transform: none !important;
}

.v-tabs:not(.v-tabs--vertical) .v-tab {
  width: 100% !important
}
</style>
