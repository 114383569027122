<template>
    <div>
        <layout>
            <template v-slot:content>

                <v-card color="primary">
                    <div class="d-md-flex" :style="{
                        height: '30vh', backgroundImage: 'url(' + require('@/assets/belat_contact_us.png') + ')',
                        backgroundPositionX: '40%',
                        backgroundSize: 'none'
                    }">
                        <h1 class="font-weight-regular white--text d-flex align-end pl-16 pb-5">
                            Perguntas frequentes
                        </h1>
                    </div>
                </v-card>

                <div class="mt-lg-4 mx-4 mt-lg-8 px-lg-16 overflow-y-auto" style="height: 55vh;">
                    <v-row>
                        <v-expansion-panels accordion>
                            <v-expansion-panel class="ma-1 pa-1" style="border-left: 2px solid #0489b1a9;"
                                v-for="(faq, index) in faqs" :key="index">
                                <v-expansion-panel-header
                                    class="font-weight-bold">{{ faq.question }}</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    {{ faq.answer }}
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>

                        <p style="text-align:start"
                            class="font-weight-medium mt-5 text-h4 text-decoration-underline primary--text">Contato</p>
                        <div class="primary--text">
                            Caso não tenha encontrado a resposta para sua dúvida no campo 'Perguntas frequentes' aqui ao
                            lado, por favor deixe sua mensagem que lhe responderemos por e-mail.
                            <p class="primary--text mt-3 font-weight-bold">Título da sua mensagem</p>
                            <v-textarea v-model="texto" outlined label="Escreva sua mensagem aqui!"></v-textarea>
                            <div style="display:flex; justify-content:center">
                                <v-btn @click="sendEmailContact" color="secondary"
                                    class="rounded-xl px-16 mx-16">Enviar</v-btn>
                            </div>
                        </div>

                    </v-row>
                </div>

            </template>
        </layout>
    </div>
</template>

<script>
import layout from "../../layouts/internalLayout.vue";
import axios from "axios";
export default {
    metaInfo() {
        return {
            title: 'BELat - Fale Conosco'
        }
    },
    components: {
        layout,
    },
    data() {
        return {
            userId: localStorage.getItem("userId"),
            token: localStorage.getItem("token"),
            urlEnv: process.env.VUE_APP_SERVICE_URL,
            faqs: [],
            user: {
                name: '',
                email: '',
                phone_number: '',
            },
            texto: '',
            right: null,
        };
    },
    methods: {
        async loadFAQs() {
            try {
                const currentLocale = this.$i18n.locale;
                const response = await axios.get(`/locales/${currentLocale}_faqs.json`);
                this.faqs = response.data;
            } catch (error) {
                console.error("Error loading FAQs:", error);
            }
        },
        removeNonNumeric(value) {
            return value?.replace(/\D/g, "");
        },
        async sendEmailContact() {
            // Carregar dados do usuário antes de enviar o e-mail
            await this.getUserData();

            const userContact = {
                nome: this.user.name,
                telefone: this.removeNonNumeric(this.user.phone_number),
                email: this.user.email,
                texto: this.texto,
            };

            console.log(userContact);

            try {
                const response = await axios.post(this.urlEnv + "sendContactEmail", userContact, {
                    headers: {
                        'Authorization': `Bearer ${this.token}`,
                        'X-Frontend-URL': window.location.origin
                    }
                });

                if (response.data.status) {
                    this.$toast.success('Mensagem enviada com sucesso!');
                } else {
                    this.$toast.error('Erro ao enviar a mensagem:');
                }
            } catch (error) {
                this.$toast.error('Erro ao enviar a mensagem:', error);
                this.$toast.error('Erro ao enviar a mensagem. Tente novamente mais tarde.');
            }
        },
        async getUserData() {
            const config = {
                headers: { Authorization: `Bearer ${this.token}` },
            };

            try {
                const response = await axios.get(this.urlEnv + "user", config);
                let userData = response.data;
                this.user.name = userData.name;
                this.user.email = userData.email;
                this.user.phone_number = userData.phone_number;
                console.log(userData);
            } catch (error) {
                console.log('Erro ao obter dados do usuário:', error);
            }
        },
    },
    async mounted() {
        await this.getUserData();
        this.loadFAQs();
    },
    computed: {
    },
};
</script>

<style scoped>
.v-navigation-drawer__border {
    display: none;
}

.rounded-card {
    border-radius: 20px;
    border: 1px solid #032a5a
}

.rounded-card-2 {
    border-radius: 20px;
    border: 1px solid #DF6E8B
}

.rounded-card-3 {
    border-radius: 20px;
    border: 1px solid #14aa95;
}

.opacityButton {
    background: rgba(255, 255, 255, 0.226);
}

.oi {
    border-bottom: 10px solid black;
}

.text-h4,
.text-xl-h4,
.text-xl-h5,
.text-h3,
.text-h5 .text-h6,
.white--text,
.text-center,
.d-flex {
    font-family: 'Raleway', cursive !important;
}

@media (min-width: 2560px) {
    .textHome {
        font-size: 24px;
    }
}

@media (min-width: 1920px) and (max-width: 2559px) {
    .textHome {
        font-size: 17px !important;
    }
}

@media (min-width: 1280px) and (max-width: 1919px) {
    .textHome {
        font-size: 10.5px;
    }
}

@media (max-width: 1279px) {
    .textHome {
        font-size: 12px;
    }
}</style>