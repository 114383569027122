<template>
  <v-container class="custom-container" style="height: 100%; padding: 0;">
   
      <v-row style="height:100%; width:auto;">
        <v-app-bar class="custom-container2" fixed color="#DF6E8B" style="height: 15px; margin-top: 0px; transform: translateY(0px); top: auto !important; left: 0px; right: 0px; z-index: 99; bottom: 0px;">
    
        </v-app-bar>
        <v-col cols="12" md="7" class="primary d-flex flex-column justify-center position-relative">
          <div class="d-flex justify-end">
            <v-img class="teste" src="../../assets/gif_banca.gif"></v-img>
          </div>
          <!-- Texto de background -->
          <div class="col-9">
            <div class="d-flex align-start h-100 flex-column pl-16 mt-16" style="position: relative;">
              <v-img class="mb-5" max-width="250" src="../../assets/banca_etica.png"></v-img>
              <h3 class="pt-10 font-weight-600 white--text">Olá, <br /> que bom ver você aqui.</h3>
              <h1 class="custom-letter-spacing mt-5 text-subtitle-1 font-weight-light white--text mb-3">Aqui, na plataforma de investimento da 
                <b><span>BELAT</span></b>, você pode conectar seus investimentos com seus valores, e gerar um impacto socioambiental positivo em qualquer operação de sua escolha.
              </h1>
              <h1 class="custom-letter-spacing mt-2 text-subtitle-1 font-weight-light white--text mb-3">Impacto é uma questão de escolha. 
                Venha conosco e faça a sua.</h1>
              <!-- <h1 class="custom-letter-spacing mt-2 text-subtitle-1 font-weight-light white--text mb-3">Gera impacto
                positivo, gera saúde, gera possibilidade, gera preservação.</h1> -->
            </div>
          </div>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="5" lx="5" class="pa-20 white"
          style="z-index: 10; display: flex; justify-content: center; align-items: center;">
          <div style="width: 70%; max-width: 420;">

            <v-row class="text-center">
              <v-col cols="12">

                <h1 class="primary--text text-h4 pt-lg-10">Criar uma conta</h1>
              </v-col>
              <!-- <v-col cols="12" class="pa-2">
                <v-select prepend-inner-icon="mdi-information-outline" hide-details outlined :items="items" label="País de origem"
                  dense></v-select>
              </v-col> -->
              <v-col cols="12" class="pa-2">
                <v-text-field v-model="name" class="rounded-lg" label="Nome"
                  hide-details="auto" outlined type="text" dense ></v-text-field>
              </v-col>
              <!-- <v-col cols="6" class="pa-2">
                <v-text-field v-model="surname" class="rounded-lg" label="Sobrenome"
                  hide-details="auto" outlined type="text" dense @input="updateFullName"></v-text-field>

              </v-col> -->
              <v-col cols="12" class="pa-2">
                <v-text-field :rules="rulesEmail" type="email" v-model="email"
                  class="rounded-lg" label="Email" hide-details="auto" outlined dense></v-text-field>
              </v-col>
              <v-col cols="12" class="pa-2">
                <v-text-field v-mask="'(##) # ####-####'" v-model="phone_number"
                  class="rounded-lg" label="Telefone" hide-details="auto" outlined dense></v-text-field>
              </v-col>
              <v-col cols="12" class="pa-2">
                <v-text-field :append-icon="showPass1 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPass1 = !showPass1" :type="showPass1 ? 'text' : 'password'" class="rounded-lg"
                  label="Senha" :rules="rules" hide-details="auto" name="password" v-model="password" outlined
                  dense></v-text-field>
              </v-col>
              <v-col cols="12" class="pa-2">
                <v-text-field :append-icon="confirmPass1 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="confirmPass1 = !confirmPass1" :type="confirmPass1 ? 'text' : 'password'"
                  class="rounded-lg" v-model="confirmPassword" :rules="confirmPasswordRules" label="Confirme a Senha"
                  hide-details="auto" outlined dense></v-text-field>
              </v-col>
              <v-col cols="12" class="pa-2">
                <v-checkbox v-model="clickJuridica" dense hide-details>
                  <template v-slot:label>
                    <h5 class="primary--text" style="font-weight: 500;">
                      Sou investidor(a) pessoa juridica
                    </h5>
                  </template>
                </v-checkbox>
                <v-checkbox v-model="checkbox" dense hide-details>
                  <template v-slot:label>
                    <div>
                      <h5 class="primary--text" style="font-weight: 500;">
                        Eu concordo com os <a href="/pdf/TERMO_E_USO_plataforma_Belat.pdf" target="_blank" @click.stop><b><u>termos e condições</u></b></a>
                      </h5>
                    </div>
                  </template>
                </v-checkbox>
                <v-checkbox v-model="checkbox2" dense hide-details>
                  <template v-slot:label>
                    <div>
                      <h5 class="primary--text" style="font-weight: 500;">
                        Eu concordo com as <a href="/pdf/POLITICA_DE_PRIVACIDADE_plataforma_Belat_24_0507.pdf" target="_blank" @click.stop><b><u>políticas de privacidade</u></b></a>
                      </h5>
                    </div>
                  </template>
                </v-checkbox>
              </v-col>
              <v-col cols="12" class="d-flex justify-center">
                <v-hover v-slot="{ hover }">
                  <v-btn :class="{ 'on-hover': hover }" block rounded class="pa-5 py-6 transition-fast-in-fast-out"
                    :color="hover ? 'primary' : 'secondary'" @click="submit" :disabled="!(checkbox && checkbox2) || disabled">
                    <span>Criar conta</span>
                  </v-btn>
                </v-hover>
              </v-col>
              <v-col cols="12" class="d-flex justify-center">
                <span class="text-center blue-grey--text text--darken-1">
                  Já tem uma conta?
                  <a class="text-decoration-none secondary--text " @click="goToLogin">
                    Clique aqui
                  </a>
                </span>
              </v-col>
            </v-row>
          </div>
        </v-col>

      </v-row>

  </v-container>
  <!-- <v-card class="mx-auto" width="100%" outlined> -->
  <!-- </v-card> -->
</template>

<script>
import axios from "axios";
import CryptoJS from 'crypto-js';

export default {
  metaInfo() {
    return {
      title: 'BELat - Cadastro'
    }
  },
  data() {
    return {
      clickJuridica:false,
      // full_name: null,
      items: [
        'Brasil',
        'Chile'
      ],
      errorMessageMap: {
        name: "O campo nome é obrigatorio",
        email: "O campo email é obrigatorio",
        phone_number: "O campo telefone é obrigatorio",
        password: "O campo senha é obrigatorio",
        confirmPassword: "Confirme a senha.",
      },
      urlEnv: process.env.VUE_APP_SERVICE_URL,
      name: null,
      phone_number: null,
      email: null,
      password: null,
      confirmPassword: null,
      showPass1: false,
      confirmPass1: false,
      showPass2: true,
      confirmPass2: true,
      isLoginMode: false,
      disabled: false,
      rulesEmail: [
        (v) => this.validateEmail(v) || "E-mail inválido",
      ],
      rules: [
        (v) => !!v || "Campo obrigatório",
        (v) =>
          (v && v.length >= 8) || "A senha deve ter pelo menos 8 caracteres",
        (v) =>
          (v && /[A-Z]/.test(v)) ||
          "A senha deve conter pelo menos uma letra maiúscula",
        (v) =>
          (v && /[a-z]/.test(v)) ||
          "A senha deve conter pelo menos uma letra minúscula",
        (v) =>
          (v && /\d/.test(v)) || "A senha deve conter pelo menos um número",
      ],
      confirmPasswordRules: [
        (value) => !!value || "Digite a senha novamente",
        (value) => value === this.password || "As senhas são diferentes",
      ],
      checkbox: false,
      checkbox2: false,
    };
  },
  methods: {
    encrypt(data) {
      return CryptoJS.AES.encrypt(data, 'j8zMKJnI3MLiU2KzCgAhsqBvTjFXB6D0rXCq1m7DHEM=').toString();
    },
    updateFullName() {
      this.full_name = `${this.name} ${this.surname}`;
    },
    validateEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    removeNonNumeric(value) {
      return value?.replace(/\D/g, "");
    },
    submit() {
      this.disabled = true;
      const fields = ["name", "email", "phone_number", "password", "confirmPassword"];
      for (const field of fields) {
        if (!this[field]) {
          this.$toast.error(this.errorMessageMap[field]);
          this.disabled = false;
          return;
        }
      }

      const emailValid = this.rulesEmail.every(rule => rule(this.email) === true);
      const passwordValid = this.rules.every(rule => rule(this.password) === true);
      const confirmPasswordValid = this.confirmPasswordRules.every(rule => rule(this.confirmPassword) === true);

      if (!passwordValid || !confirmPasswordValid || !emailValid) {
          this.$toast.error("Por favor, corrija os erros nos campos.");
          this.disabled = false;
          return;
      }

      let url = 'createLogin'
      if(this.clickJuridica) url = 'createLead';
      
      const userData = {
        name: this.name,
        phone_number: this.removeNonNumeric(this.phone_number),
        email: this.email,
        password: this.password,
        password_confirmation: this.confirmPassword
      };

      axios
        .post(this.urlEnv + url, userData, {headers: {'X-Frontend-URL': window.location.origin}})
        .then(() => {
          // this.$toast.success("Cadastrado com sucesso")
          if (this.clickJuridica) {
            const encryptedUser = this.encrypt(JSON.stringify(userData));
            localStorage.setItem('encryptedUser', encryptedUser);
            this.$router.replace("/juridica");
          } else {
            this.$router.push({
              name: "SendEmail",
              query: {email: this.encrypt(this.email)}
            });
          }
        })
        .catch((err) => {
          this.disabled = false;
          if(err?.response?.data?.mensagem){
            Object.values(err.response.data.mensagem).forEach((input)=> {
              this.$toast.error(input.length > 0 ? input[0] : input);
            });
          }else{
            console.log(err);
          }
        });
    },
    goToLogin() {
      this.$router.replace("/");
    },
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');

.custom-letter-spacing {
  font-family: 'Montserrat', sans-serif !important;
}

.v-messages,
.v-text-field__details {
  display: none;
}

.custom-container {
  max-width: 1440px;
  margin: 0 auto;
  border: 1px solid #fff;
}

.custom-container2 {
  max-width: 1461px;
  margin: 0 auto;
  border: 1px solid #fff;
}

.teste {
  position: absolute;
  top: -25%;
  left: 27%;
  bottom: 2%;
  max-width: 40%;
}

.text-h4,
.text-xl-h4,
.text-xl-h5,
.text-h3,
.text-h5 .text-h6,
.white--text,
.text-center,
.d-flex {
    font-family: 'Raleway', cursive !important;
}
</style>