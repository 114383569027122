import { render, staticRenderFns } from "./finishPage.vue?vue&type=template&id=660b73d5&scoped=true"
import script from "./finishPage.vue?vue&type=script&lang=js"
export * from "./finishPage.vue?vue&type=script&lang=js"
import style0 from "./finishPage.vue?vue&type=style&index=0&id=660b73d5&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "660b73d5",
  null
  
)

export default component.exports