<template>
  <div>
    <layout>
      <template v-slot:content>
        <div class="d-flex justify-center mt-5">
          <h2 class="text-center primary--text">{{ $t('my_investments') }}</h2>
        </div>
        <v-data-table :page.sync="page" :headers="headers" :items="desserts" 
          class=" mt-10 px-5 custom-data-table" hide-default-footer mobile-breakpoint="xs"
          @page-count="pageCount = $event"><template slot="no-data">
            {{ $t('no_investments_found') }}
          </template></v-data-table>
       
      </template>
    </layout>
  </div>
</template>

<script>
import axios from "axios";
import layout from "../../layouts/internalLayout.vue";
export default {
  metaInfo() {
    return {
      title: 'BELat - Meus Investimentos'
    }
  },
  components: {
    layout,
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 9,
      userId: localStorage.getItem("userId"),
      headers: [
      { text: this.$t('start_date'), value: "data_inicio" },
        {
          text: this.$t('business'),
          align: "start",
          sortable: false,
          value: "nome_projeto",
        },
        { text: this.$t('investiment_value'), value: "valor_investido" },
        { text: this.$t('rate'), value: "taxa_retorno" },
        { text: this.$t('deadline'), value: "prazo" },
        { text: this.$t('status'), value: "status.nome" },
      ],
      desserts: [],
      token: localStorage.getItem("token"),
      urlEnv: process.env.VUE_APP_SERVICE_URL,
      items: [
        { title: "Meus Investimentos", icon: "mdi-home-city" },
        { title: "Meus dados", icon: "mdi-account", route: "/user/MyInfo" },
      ],
      campos: [
        { title: "Acesso", icon: "mdi-account-outline" },
        { title: "Dados Pessoais", icon: "mdi-card-account-details-outline" },
        { title: "Dados Bancarios", icon: "mdi-credit-card-chip-outline" },
        { title: "Dados Profissionais", icon: "mdi-briefcase-outline" },
        { title: "Endereço", icon: "mdi-home-outline" },
        { title: "Dados de contato", icon: "mdi-cellphone" },
      ],
      right: null,
    };
  },
  methods: {
    formatPrazo(prazo) {
      return `${prazo} meses`;
    },
    formatDate(date) {
      if (date) {
        const [year, month, day] = date.split("-");
        return `${day}/${month}/${year}`;
      }
      return "";
    },
    formatCurrency(value) {
      const formatter = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
      return formatter.format(value);
    },
    formatPercentage(value) {
      const formatter = new Intl.NumberFormat("pt-BR", {
        style: "percent",
        minimumFractionDigits: 2,
      });
      const formattedValue = formatter.format(value / 100);
      return formattedValue + " a.m";
    },
    async getInvestiments() {
      const config = {
        headers: { Authorization: `Bearer ${this.token}` },
      };
      // console.log(Object.values(this.desserts))
      // if(Object.values(this.desserts).length <= 0) {
      //     return
      //   } else {
      // this.desserts = {};
      try {
        axios
          .get(this.urlEnv + `getInvestiments`, config)
          .then((response) => {
            this.desserts = Object.values(response.data.data.investimentos).map(
              (item) => {
                return {
                  ...item,
                  valor_total_projeto: this.formatCurrency(
                    item.valor_total_projeto
                  ),
                  prazo: this.formatPrazo(item.prazo),
                  valor_investido: this.formatCurrency(item.valor_investido),
                  taxa_retorno: this.formatPercentage(item.taxa_retorno),
                  data_inicio: this.formatDate(item.data_inicio),
                };
              }
            );
            console.log(response.data.data)
          })
          .catch((error) => {
            console.error(error);
          });
      } catch (err) {
        console.log(err);
      }
      // }
    },

    setCurrentScreen(index) {
      this.currentScreenIndex = index;
    },
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
  mounted() {
    this.getInvestiments();
  },
  computed: {
    currentScreen() {
      return this.screens[this.currentScreenIndex];
    },
    totalScreens() {
      return this.screens.length;
    },
  },
};
</script>

<style>
.custom-data-table .v-data-table-header {
  background-color: #00265b;
}

.text-start {
  font-family: 'Raleway'
}

th > span {
  font-family: 'Raleway';
  color: white;
}
.custom-data-table .v-data-table-header th .v-icon {
  color: white !important;
}
.custom-data-table tbody {
  background-color: #EDF0F3;
}
</style>
