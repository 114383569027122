<template>
  <div>
    <layout>
      <template v-slot:content>
        <div class="d-flex justify-center mt-5">
          <h2 class="text-center primary--text">{{ $t('my_investments') }}</h2>
        </div>
        <v-data-table
          :page.sync="page"
          :headers="headers"
          :items="desserts"
          class="mt-10 px-5 no-background"
          hide-default-footer
          mobile-breakpoint="xs"
          @page-count="pageCount = $event"
          :disable-sort="true"
        >
          <template slot="no-data">
            {{ $t('no_investments_found') }}
          </template>
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-select
                v-model="sortBy"
                :items="sortOptions"
                label="Ordenar por"
                single-line
                hide-details
              ></v-select>
            </v-toolbar>
          </template>
          <template v-slot:item="{ item }">
            <v-card class="mb-4 custom-card" >
                <v-card-title>
                    <span style="color:#032A5A; font-size:16px"> {{ item.nome_projeto }}</span></v-card-title><v-divider></v-divider>
              <v-card-text>
                <table style="width: 100%;">
                  <tr>
                    <td style="color:#032A5A"><strong>{{ $t('start_date') }}:</strong></td>
                    <td class="text-center" style="color:#032A5A">{{ item.data_inicio }}</td>
                  </tr>
                  <tr>
                    <td style="color:#032A5A"><strong>{{ $t('business') }}:</strong></td>
                    <td class="text-center" style="color:#032A5A">{{ item.nome_projeto }}</td>
                  </tr>
                  <tr>
                    <td style="color:#032A5A"><strong>{{ $t('investiment_value') }}:</strong></td>
                    <td class="text-center" style="color:#032A5A">{{ item.valor_investido }}</td>
                  </tr>
                  <tr>
                    <td style="color:#032A5A"><strong>{{ $t('rate') }}:</strong></td>
                    <td class="text-center" style="color:#032A5A">{{ item.taxa_retorno }}</td>
                  </tr>
                  <tr>
                    <td style="color:#032A5A"><strong>{{ $t('deadline') }}:</strong></td>
                    <td class="text-center" style="color:#032A5A">{{ item.prazo }}</td>
                  </tr>
                  <tr>
                    <td style="color:#032A5A"><strong>{{ $t('status') }}:</strong></td>
                    <td class="text-center" style="color:#032A5A">{{ item.status.nome }}</td>
                  </tr>
                </table>
              </v-card-text>
            </v-card>
          </template>
        </v-data-table>
      </template>
    </layout>
  </div>
</template>

<script>
import axios from "axios";
import layout from "../../layouts/internalLayoutMobile.vue";

export default {
  metaInfo() {
    return {
      title: 'BELat - Meus Investimentos'
    }
  },
  components: {
    layout,
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 9,
      userId: localStorage.getItem("userId"),
      sortBy: '',
      sortOptions: [
        { text: this.$t('start_date'), value: 'data_inicio' },
        { text: this.$t('investiment_value'), value: 'valor_investido' },
        { text: this.$t('rate'), value: 'taxa_retorno' },
        { text: this.$t('deadline'), value: 'prazo' },
        { text: this.$t('status'), value: 'status.nome' },
      ],
      headers: [],
      desserts: [],
      token: localStorage.getItem("token"),
      urlEnv: process.env.VUE_APP_SERVICE_URL,
      items: [
        { title: "Meus Investimentos", icon: "mdi-home-city" },
        { title: "Meus dados", icon: "mdi-account", route: "/user/MyInfo" },
      ],
      campos: [
        { title: "Acesso", icon: "mdi-account-outline" },
        { title: "Dados Pessoais", icon: "mdi-card-account-details-outline" },
        { title: "Dados Bancarios", icon: "mdi-credit-card-chip-outline" },
        { title: "Dados Profissionais", icon: "mdi-briefcase-outline" },
        { title: "Endereço", icon: "mdi-home-outline" },
        { title: "Dados de contato", icon: "mdi-cellphone" },
      ],
      right: null,
    };
  },
  methods: {
    formatPrazo(prazo) {
      return `${prazo} meses`;
    },
    formatDate(date) {
      if (date) {
        const [year, month, day] = date.split("-");
        return `${day}/${month}/${year}`;
      }
      return "";
    },
    formatCurrency(value) {
      const formatter = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
      return formatter.format(value);
    },
    formatPercentage(value) {
      const formatter = new Intl.NumberFormat("pt-BR", {
        style: "percent",
        minimumFractionDigits: 2,
      });
      const formattedValue = formatter.format(value / 100);
      return formattedValue + " a.m";
    },
    async getInvestiments() {
      const config = {
        headers: { Authorization: `Bearer ${this.token}` },
      };
      try {
        const response = await axios.get(this.urlEnv + `getInvestiments`, config);
        this.desserts = Object.values(response.data.data.investimentos).map(item => {
          return {
            ...item,
            valor_total_projeto: this.formatCurrency(item.valor_total_projeto),
            prazo: this.formatPrazo(item.prazo),
            valor_investido: this.formatCurrency(item.valor_investido),
            taxa_retorno: this.formatPercentage(item.taxa_retorno),
            data_inicio: this.formatDate(item.data_inicio),
          };
        });
        console.log(response.data.data);
      } catch (error) {
        console.error(error);
      }
    },
  },
  mounted() {
    this.getInvestiments();
  },
};
</script>

<style scoped>
.custom-card {
  border-radius: 16px; /* Ajuste o valor conforme necessário */
}
.text-start {
  font-family: 'Raleway';
}

th > span {
  font-family: 'Raleway';
  color: white;
}

.v-card {
  margin-bottom: 16px; /* Adiciona espaço entre os itens */
  border: 1px solid #dddddd; /* Ajuste a espessura e a cor da borda conforme necessário */

}

table tr td {
  padding: 0.5rem 0; /* Adicione padding para espaçamento dentro das células */
}
</style>
