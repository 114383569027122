import Config from "./ConfigPage.vue"

const routes = {
  children: [
    {
      name: 'config',
      path: 'config', 
      component: Config,
      meta: {
        requiresAuth: true,
      },
    },
  ]
};

export default routes;
