<template>
  <v-card>
    <v-btn icon class="close-button" @click="closeScreen">
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <div class="d-flex justify-center">
      <v-card-title class="text-h6 primary--text">{{ invested ? $t('investment_reserve') : $t('investment_simulation') }}</v-card-title>
    </div>
    <hr />
    <div class="mx-1">
      <v-card-text class="py-0 my-0">
        <v-row class="mt-1 py-2 my-0">
          <v-col cols="7" class="text-center py-0 my-0" sm="7">
            <h1 class="text-body-1 primary--text">{{invested ? "Valor da Reserva" : "Valor da Simulação"}}</h1>
            <v-text-field hide-details="auto" dense color="grey" v-model="investmentValue" @input="investmentValue = formatarValor(investmentValue)"
              :disabled="invested"></v-text-field>
            <span style="font-size: 10px">Valor mínimo: {{ formatarValor(negocio.ticket_minimo) }} </span>
          </v-col>
          <v-col cols="5" class="text-center py-0 my-0">
            <h1 class="text-body-1 primary--text">Taxa de retorno
              <v-tooltip max-width="350" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" dark size="15" v-bind="attrs" v-on="on">
                    mdi-information-outline
                  </v-icon>
                </template>
                <span>A Taxa de Juros acima é igual ao seu Retorno Mensal Bruto (antes do desconto de IR, custos de transferência e potencial inadimplência).</span>
              </v-tooltip>
            </h1>
            <p class="text-center d-flex justify-center primary--text">
              {{ numberToPercentage(parseFloat(negocio.taxa ))}} a.m.
            </p>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-subtitle class="primary--text text-center text-h6 py-0 my-0">{{ invested ?  $t('reserve_data') : $t('simulation_data') }}</v-card-subtitle>
      <v-card-text class="d-flex pt-2 pb-1 my-1">
        <h3 class="text-body-1 primary--text">{{ $t('deadline') }} ({{ negocio.prazo }} meses)</h3>
        <v-spacer></v-spacer>
        <h3 class="text-body-1 primary--text">{{ negocio.numero_parcelas }} Parcelas</h3>
      </v-card-text>
      <hr />
      <v-card-text class="d-flex py-2 my-1">
        <span class="text-body-1 primary--text" style="font-size: 14px !important;">Valor da parcela (bruto de IR)</span>
        <v-spacer></v-spacer>
        <span class="text-body-1 primary--text" style="font-size: 14px !important;">{{ parcelas }}</span>
      </v-card-text>
      <hr />
      <v-card-text class="d-flex py-2 my-1">
        <h3 class="text-body-1 primary--text" style="font-size: 14px !important;">Valor Total (líquido de IR)
          <v-tooltip max-width="350" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" dark size="15" v-bind="attrs" v-on="on">
                mdi-information-outline
              </v-icon>
            </template>
            <span>Desconto do IR seguindo a tabela regressiva da renda fixa.</span>
          </v-tooltip>
        </h3>
        <v-spacer></v-spacer>
        <h3 class="text-body-1 primary--text" style="font-size: 14px !important;">{{ total_liquido }}</h3>
      </v-card-text>
      <div class="mt-2 mb-5">
        <v-btn v-if="!invested" rounded color="secondary" 
          :dark="titularUser && titularUser.status && titularUser.status.codigo == '02'" class="pa-3 mb-3" style="width: 100%; font-size: 10px;" 
          :disabled="!(titularUser && titularUser.status && titularUser.status.codigo == '02') || !valorMinimoAlcancado || cantInvest"
          @click="investNow">{{ titularUser && titularUser.status && titularUser.status.codigo != '02' ? 'Aguardando aprovação do Cadastro' :$t('reserve_now') }}</v-btn>
        <v-btn v-if="invested" @click="investment" rounded color="primary" dark class="pa-3 mb-3" style="width: 100%">{{$t('invest_now') }}</v-btn>
      </div>
    </div>

    <!-- Loading Overlay -->
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <!-- Success Dialog -->
    <v-dialog v-model="showSuccessDialog" max-width="200">
      <v-card class="text-center py-4" style="border-radius: 10px;">
        <v-icon class="success-icon" color="green" size="64">mdi-check-circle-outline</v-icon>
        <v-card-text class="pt-2">Reserva Solicitada com sucesso</v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import axios from "axios";
import { numberToReal, numberToPercentage } from "../../../js/belatUtils";
export default {
  props: {
    negocio: Object,
    titularUser: Object,
  },
  data() {
    return {
      invested: false,
      businessId: localStorage.getItem("negocio"),
      token: localStorage.getItem("token"),
      urlEnv: process.env.VUE_APP_SERVICE_URL,
      investmentValue: "",
      returnRate: "",
      loading: false,
      showSuccessDialog: false,
    };
  },
  methods: {
    formatarValor(valor) {
      valor = valor.replace(/\D/g, "");
      valor = (parseFloat(valor) / 100).toFixed(2).replace(".", ",");
      valor = "R$ " + valor.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
      return valor;
    },
    closeScreen() {
      this.resetData();
      this.$emit("close");
    },
    resetData() {
      this.invested = false;
      this.investmentValue = "";
    },
    investment() {
      const config = {
        headers: { Authorization: `Bearer ${this.token}` },
      };
      axios
        .get(this.urlEnv + `checkCadastro`, config)
        .then((response) => {
          if (response.data.status) {
            this.createInvestiment();
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
          false;
        });
    },
    investNow() {
      this.invested = true;
    },
    createInvestiment() {
      this.loading = true;
      const formatter = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      const investments = formatter.format(parseFloat(this.investmentValue.replace(/[^\d,]/g, '').replace(',', '.'))).replaceAll(',', '');
      const config = {
        headers: { Authorization: `Bearer ${this.token}` },
      };
      const data = {
        negocio_id: this.businessId,
        valor_investido: investments,
      };
      try {
        axios
          .post(this.urlEnv + "createInvestiment", data, config)
          .then(() => {
            this.loading = false;
            this.showSuccessDialog = true;
            setTimeout(() => {
              this.showSuccessDialog = false;
              this.$router.push({
                name: "MobileMyInvestiments",
              });
            }, 2000); // Mostra o diálogo por 2 segundos
          })
          .catch((error) => {
            this.loading = false;
            console.log(error);
          });
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
  },
  computed: {
    cantInvest() {
      if (this.investmentValue) return parseFloat(this.investmentValue.replace(/[^\d,]/g, '').replace(',', '.')) > parseFloat(this.negocio.valor);
      else return true;
    },
    parcelas() {
      if (this.investmentValue && this.negocio.numero_parcelas) {
        const valorInvestido = parseFloat(this.investmentValue.replace(/[^\d,]/g, '').replace(',', '.'));
        if (valorInvestido >= parseFloat(this.negocio.ticket_minimo)) {
          const fator_parcelas = parseFloat(this.negocio.fator_parcela);
          const valorParcela = valorInvestido * fator_parcelas;
          return valorParcela.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        }
      }
      return 0;
    },
    imposto() {
      if (this.investmentValue && this.negocio.numero_parcelas) {
        const valorInvestido = parseFloat(this.investmentValue.replace(/[^\d,]/g, '').replace(',', '.'));
        if (valorInvestido >= parseFloat(this.negocio.ticket_minimo)) {
          const fator_ir = parseFloat(this.negocio.fator_ir);
          const valor_ir = valorInvestido * fator_ir;
          return valor_ir.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        }
      }
      return 0;
    },
    total_liquido() {
      if (this.investmentValue && this.negocio.numero_parcelas) {
        const valorInvestido = parseFloat(this.investmentValue.replace(/[^\d,]/g, '').replace(',', '.'));
        if (valorInvestido >= parseFloat(this.negocio.ticket_minimo)) {
          const fator_ir = parseFloat(this.negocio.fator_ir);
          const fator_parcelas = parseFloat(this.negocio.fator_parcela);
          const valorParcela = valorInvestido * fator_parcelas;
          const valorTotalParcelas = valorParcela * this.negocio.numero_parcelas;
          const valor_final = valorTotalParcelas - (valorTotalParcelas * fator_ir);
          return valor_final.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        }
      }
      return 0;
    },
    valorMinimoAlcancado() {
      if (this.investmentValue) {
        const valorInvestido = parseFloat(this.investmentValue.replace(/[^\d,]/g, '').replace(',', '.'));
        if (valorInvestido >= parseFloat(this.negocio.ticket_minimo)) {
          return true;
        }
      }
      return false;
    },
    investmentValueFormatted() {
      if (this.investmentValue) {
        const value = parseFloat(this.investmentValue.replace(",", "."));
        return value.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }
      return "";
    },
    numberToReal() {
      return numberToReal;
    },
    numberToPercentage() {
      return numberToPercentage;
    },
  },
  watch: {
    negocio: {
      immediate: true,
      handler(newVal) {
        console.log("Selected Business:", newVal);
      },
    },
  },
};
</script>

<style scoped>
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
}

.text-h6 {
  color: black !important;
  font-weight: 400 !important;
}

.v-btn.grey.dark {
  background-color: grey !important;
  color: white !important;
}

.v-dialog > .v-card > .v-card__text.v-card__text {
  padding-bottom: 13px;
}

hr {
  display: block;
  border: 0;
  border-top: 1px solid #ccc;
}
</style>
