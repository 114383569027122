<template>
  <div>
    <div class="d-flex flex-column align-center w-100">
      <v-row style="width: 80%;" class="pt-6">
        <v-col cols="12" md="4">
          <!-- <label>Email</label> -->
          <!-- {{ infoAditional.acesso.login }} -->
          <v-text-field
            v-model="titularUser.cargo"
            class="rounded-lg"
            hide-details="auto"
            :label="$t('position')"
            outlined
            dense
            :disabled="!editing"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            class="rounded-lg"
            v-model="infoTotal.valor_do_patrimonio"
            hide-details="auto"
            label="Valor do Patrimônio Financeiro"
            outlined
            @input="formatarPatrimonio"
            dense
            :disabled="!editing"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            class="rounded-lg"
            label="Renda mensal"
            hide-details="auto"
            v-model="titularUser.salario"
            outlined
            @input="formatarSalario"
            dense
            :disabled="!editing"
          ></v-text-field>
        </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              class="rounded-lg"
              label="Email"
              hide-details="auto"
              v-model="infoAditional.login"
              outlined
              disabled
              dense
            ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-mask="'(##) # ####-####'"
            class="rounded-lg"
            :label="$t('phone_number')"
            hide-details="auto"
            v-model="user.phone_number"
            outlined
            disabled
            dense
          ></v-text-field>
          </v-col>
      </v-row>
    </div>
    <v-col cols="12" class="d-flex justify-center">
      <v-hover v-slot="{ hover }">
        <v-btn
          :class="{ 'on-hover': hover }"
          class="transition-fast-in-fast-out mr-2"
          :color=" editing ? 'white' : 'button'"
          @click="editing = !editing"
          :dark="!editing"
          rounded
        >
          {{ editing ? 'Cancelar' : 'Editar' }}
        </v-btn>
      </v-hover>
      <v-hover v-slot="{ hover }" v-if="editing">
        <v-btn
          :class="{ 'on-hover': hover }"
          class="transition-fast-in-fast-out"
          color="primary"
          @click="updateTitular"
          rounded
        >
          <span>{{$t('confirm')}}</span>
        </v-btn>
      </v-hover>
    </v-col>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      userId: localStorage.getItem("userId"),
      titularUser: {},
      infoTotal: {},
      infoAditional: {},
      password: null,
      showPass1: false,
      confirmPass1: false,
      editing: false,
      token: localStorage.getItem("token"),
      urlEnv: process.env.VUE_APP_SERVICE_URL,
      user: {
        name: null,
        email: null,
        phone_number: null,
        profile_photo_url: null,
      },
      rulesEmail: [(v) => this.validateEmail(v) || "E-mail inválido"],
    };
  },
  methods: {
    formatarSalario() {
      let valor = this.titularUser.salario;
      valor = valor.replace(/\D/g, "");
      valor = (valor / 100).toFixed(2).replace(".", ",");
      valor = "R$ " + valor.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
      this.titularUser.salario = valor;
    },
    formatarPatrimonio () {
      let valor = this.infoTotal.valor_do_patrimonio;
      valor = valor.replace(/\D/g, "");
      valor = (valor / 100).toFixed(2).replace(".", ",");
      valor = "R$ " + valor.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
      this.infoTotal.valor_do_patrimonio = valor;
    },
    validateEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    async getUserData() {
      const config = {
        headers: { Authorization: `Bearer ${this.token}` },
      };
      try {
        const response = await axios.get(this.urlEnv + "user", config);
        const userData = response.data;
        this.user.name = userData.name;
        this.user.email = userData.email;
        this.user.phone_number = userData.phone_number;
        this.user.profile_photo_url = userData.profile_photo_url;
        console.log(userData);
      } catch (error) {
        console.error(error);
      }
    },
    async getMeusDados() {
      const config = {
        headers: { Authorization: `Bearer ${this.token}` },
      };
      try {
        axios
          .get(this.urlEnv + `getMeusDados`, config)
          .then((response) => {
            this.titularUser = response.data.data.dados_profissionais;
            this.infoAditional = response.data.data.acesso
            this.infoTotal = response.data.data.dados_pessoais
            console.log(this.titularUser)
          })
          .catch((error) => {
            console.error(error);
          });
      } catch (err) {
        console.log(err);
      }
    },
    updateTitular() {
      const formatter = new Intl.NumberFormat('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})
      const salario = formatter.format(parseFloat(this.titularUser.salario.replace(/[^\d,]/g, '').replace(',', '.'))).replaceAll(',', '')
      const patrimonio = formatter.format(parseFloat(this.infoTotal.valor_do_patrimonio.replace(/[^\d,]/g, '').replace(',', '.'))).replaceAll(',', '')
      // const salario = this.titularUser.salario.replace(/\D/g, "");
      const config = {
        headers: { Authorization: `Bearer ${this.token}` },
      };
      const data = {
        Salario: salario,
        ValorDoPatrimonio: patrimonio,
        Cargo: this.titularUser.cargo,
        Telefone: this.user.phone_number,
      };

      try {
        axios
          .post(this.urlEnv + "updateInvestidor", data, config)
          .then((response) => {
            this.$toast.success('Titular Atualizado')
            this.editing = !this.editing;
            console.log(response.data);
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        console.log(error);
      }
    },
  },
  watch: {
    "titularUser.salario"(newValue) {
      let valor = newValue.replace(/\D/g, "");
      valor = (valor / 100).toFixed(2).replace(".", ",");
      valor = "R$ " + valor.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
      this.titularUser.salario = valor;
    },

    "infoTotal.valor_do_patrimonio"(newValue) {
      let valor = newValue.replace(/\D/g, "");
      valor = (valor / 100).toFixed(2).replace(".", ",");
      valor = "R$ " + valor.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
      this.infoTotal.valor_do_patrimonio = valor;
    },
  },
  mounted() {
    this.getUserData();
    this.getMeusDados();
  },
};
</script>
