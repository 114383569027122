<template>
  <div>
    <component :is="currentScreen" :currentPosition="currentPosition" @incrementPosition="incrementPosition"
      :currentTimeline="currentTimeline" :currentScreenIndex="currentScreenIndex" />
  </div>
</template>

<script>
import ImpactProfile from "./Pages/impactProfile.vue"
import investorPoints from "./Pages/investorPoints.vue"
import InvestProfile from "./Pages/investProfile.vue"
import DataPersonal from "./Pages/dataPersonal.vue"
// import PersonalData from "./Pages/PersonalData.vue";
import ImpactFirst from "./Pages/impactFirst.vue";
import InvestidorFirst from "./Pages/investidorFirst.vue";
import StatementFirst from "./Pages/statementFirst.vue";
import DataFirst from "./Pages/dataFirst.vue";
import FinishPage from "./Pages/finishPage.vue"
import StatementsPage from "./Pages/statementsPage.vue"
// import MyAccount from "../HomePage/HomePage.vue";
import axios from 'axios';

export default {
  metaInfo() {
    return {
      title: 'BELat - Questionário'
    }
  },
  data() {
    return {
      token: localStorage.getItem("token"),
      urlEnv: process.env.VUE_APP_SERVICE_URL,
      currentTimeline: [
        {
          position: 0,
          title: 'Perfil de Impacto',
          text: 'Saiba que tipo de investidor você é.',
        },
        {
          position: 1,
          title: 'Perfil de Investimento',
          text: 'Saiba que tipo de investidor você é.',
        },
        {
          position: 2,
          title: 'Dados pessoais',
          text: 'Queremos saber um pouco mais sobre você',
        },
        {
          position: 3,
          title: 'Declarações',
          text: 'Última etapa para poder investir',
        },
      ],
      currentPosition: 0,
      screens: [ImpactFirst, ImpactProfile, InvestidorFirst, InvestProfile, investorPoints, DataFirst, DataPersonal, StatementFirst, StatementsPage, FinishPage],
      currentScreenIndex: 0,
    };
  },
  computed: {
    currentScreen() {
      return this.screens[this.currentScreenIndex];
    },
    totalScreens() {
      return this.screens.length;
    },
  },
  methods: {
    incrementPosition() {
    this.currentPosition++;
  },
    async getMeusDados() {
  const config = {
    headers: { Authorization: `Bearer ${this.token}` },
  };
  try {
    const response = await axios.get(this.urlEnv + `getQuestions`, config);
    const questions = response.data.data.questions;
    if (questions.impactComplete && !questions.investComplete) {
      this.currentScreenIndex += 2; 
    } else if (questions.investComplete && !questions.dataComplete) {
      this.currentScreenIndex += 5; 
    } else if (questions.investComplete && questions.dataComplete && questions.impactComplete) {
      this.currentScreenIndex += 7; 
    }
    console.log(questions.dataComplete);
  } catch (error) {
    console.error(error);
  }
},

    goToPreviousScreen() {
      this.currentScreenIndex--;
    },
    goToNextScreen() {
      this.currentScreenIndex++;
    },
  },
  mounted() {
    this.getMeusDados()
  },
};
</script>
