import Transparencia from "./TransparenciaPage.vue"

const routes = {
  children: [
    {
      name: 'transparencia',
      path: 'transparencia', 
      component: Transparencia,
      meta: {
        requiresAuth: true,
      },
    },
  ]
};

export default routes;
