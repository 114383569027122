<template>
    <div class="custom-container">
      <v-row style="padding: 0; height: 100vh">
        <div style="z-index: 99; background: #00265b; width: 100%">
            <div style="display: block" class="mt-2">
              <div class="mx-6">
                <v-btn icon @click="backPage">
                    <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.97601 8L8.76012 14.6328C8.91371 14.783 9 14.9867 9 15.1991C9 15.4115 8.91371 15.6152 8.76012 15.7654C8.60653 15.9156 8.39822 16 8.18101 16C7.9638 16 7.75549 15.9156 7.6019 15.7654L0.240305 8.56633C0.164132 8.49202 0.103697 8.40375 0.0624612 8.30657C0.0212257 8.20939 0 8.10521 0 8C0 7.89479 0.0212257 7.79061 0.0624612 7.69343C0.103697 7.59625 0.164132 7.50798 0.240305 7.43367L7.6019 0.234581C7.75549 0.0843811 7.9638 0 8.18101 0C8.39822 0 8.60653 0.0843811 8.76012 0.234581C8.91371 0.38478 9 0.588495 9 0.800909C9 1.01332 8.91371 1.21704 8.76012 1.36724L1.97601 8Z" fill="white"/>
                        </svg>
                </v-btn>
              </div>
                <div class="mx-10 text-h5 secondary--text" >
                  Dados pessoais
                </div>
                <div class="mx-10 white--text text-body font-weight-light">
                    <p>Nesta etapa, tenha em mãos, o seu documento de identificação e comprovante de residência. E quando estiver pronto(a), é só clicar no botão abaixo.</p>
                </div>
            </div>
        </div>
        <v-col cols="12" class="pa-0 ma-0 mt-4 col-xl-3 col-lg-4 col-sm-5 col-xm-12">
          <div class="mx-10 mt-2">
            <timeline class="ml-xl-14 ml-lg-10 ml-sm-5 " :items="currentTimeline" :activeIndex="currentScreenIndex" :completedIndexes="completedIndexes" />
          </div>
          <div class="d-flex justify-center">
            <v-btn class="px-14 py-6 font-weight-bold" style="width: 70%;" color="secondary" @click="startQuestions" dark
              rounded>Continuar</v-btn>
            </div>
            <div class="d-flex justify-center">
                <span style="text-decoration: underline; color: gray;" @click="redirectHome" class="mt-3 font-weight-normal">
                  VER OPORTUNIDADES
                </span>
            </div>
        </v-col>
        <v-col cols="8" class="mt-12 col-xl-9 col-lg-8 col-sm-7 custom-background" :style="{
          overflowY: 'auto',
         
          padding: '0'
        }">
        </v-col>
      </v-row>
    </div>
  </template>
  
  
  
  
  <script>
  import timeline from "../../Register/Components/MobileTimeLine.vue"
  import axios from "axios";
  export default {
    props: {
      currentPosition: Number,
      currentTimeline: Array,
      currentScreenIndex: Number,
    },
    components: {
      timeline
    },
    data() {
      return {
        completedIndexes: [0,1],
        currentStage: 0,
        userId: localStorage.getItem("userId"),
        cnpj_cpf: null,
        dialog: true,
        token: localStorage.getItem("token"),
        urlEnv: process.env.VUE_APP_SERVICE_URL,
        titularUser: {},
      };
    },
    methods: {
      redirectHome() {
        this.$router.push({ name: "MobileProfile" });
      },
      backPage() {
        this.$parent.goToPreviousScreen();
      },
      getColor(position) {
        return position <= this.currentPosition ? 'secondary' : 'gray';
      },
      getImageHeight() {
        if (this.$vuetify.breakpoint.width >= 1280) {
          return this.$vuetify.breakpoint.width >= 1920 ? 500 : 250;
        } else {
          return 250;
        }
      },
      startQuestions() {
        this.$parent.goToNextScreen();
      },
      async getInvestidor() {
        const config = {
          headers: { Authorization: `Bearer ${this.token}` },
        };
        try {
          axios
            .get(this.urlEnv + `getInvestidor`, config)
            .then((response) => {
              this.titularUser = response.data.data.investidor;
              console.log(this.titularUser);
            })
            .catch((error) => {
              console.error(error);
            });
        } catch (err) {
          console.log(err);
        }
      },
      validateCPF(cpf) {
        const cpfNumbers = this.removeNonNumeric(cpf);
        if (cpfNumbers.length !== 11) {
          return false;
        }
  
        const cpfArray = cpfNumbers.split("").map(Number);
        const [digit1, digit2] = cpfArray.slice(9);
  
        const sum = (arr) => arr.reduce((acc, curr) => acc + curr, 0);
        const mod = (n, mod) => (n % mod < 2 ? 0 : mod - (n % mod));
  
        const checkDigit = (arr, factor) =>
          mod(sum(arr.map((num, idx) => num * (factor - idx))), 11);
  
        if (checkDigit(cpfArray.slice(0, 9), 10) !== digit1) {
          return false;
        }
  
        if (checkDigit(cpfArray.slice(0, 10), 11) !== digit2) {
          return false;
        }
  
        return true;
      },
  
      removeNonNumeric(value) {
        return value.replace(/\D/g, "");
      },
      close() {
        this.dialog = false;
      },
    },
  
    mounted() {
      console.log(this.currentScreenIndex)
      // this.getInvestidor();
    },
  };
  </script>
  
  
  <style scoped>
  .timeline-container {
    display: block;
    /* Exibir os itens do timeline em uma única coluna */
  }
  
  .text-regular {
    font-size: 15px;
    font-weight: 400;
  
  }
  
  .custom-container {
    /* max-width: 1440px; */
    margin: 0 auto;
    padding: 11px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .text-h4,
  .text-xl-h4,
  .text-xl-h5,
  .text-h3,
  .text-h5 .text-h6,
  .white--text,
  .text-center,
  .secondary--text,
  .d-flex {
    font-family: 'Raleway', cursive !important;
  }
  
  .gifLogo {
    position: absolute;
    top: -30%;
    left: 27%;
    bottom: 2%;
    max-width: 40%;
  }
  
  .custom-background {
    background-image: url('@/assets/imageInicial2.png');
    background-size: cover;
    /* background-position-x: 140%; */
  }
  
  @media (max-width: 600px) {
    .custom-background {
      background-image: none;
    }
  }
  </style>