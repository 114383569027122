<template>
    <div>
        <layout>
            <template v-slot:content>
                <v-col col="12" md="12" style="padding:0;">
                    <v-card>
                        <div class="d-md-flex" :style="{ height: $vuetify.breakpoint.height >= 900 ? '55vh' : '45vh' }">
                            <v-img :max-width="$vuetify.breakpoint.mdAndUp ? '50%' : 'none'"
                                src="../../assets/imageComplete.png">
                                <div style="color: #032a5a;" class="pa-12">
                                    <h1 class="text-h4">{{ $t('hello') }}, {{ user.name }}</h1>
                                    <p class="text-h6">{{ $t('welcome') }} {{ $t('to') }} Banca ética</p>
                                </div>
                            </v-img>



                            <div style="background: #032a5a; width: 100%;">
                                <div class="d-flex justify-center">
                                    <v-card-text
                                        class="white--text d-flex justify-center pt-lg-8 mx-xl-4 mt-xl-5 align-center text-subtitle-1 text-lg-h5 text-xl-h4 font-weight-bold">
                                        {{ $t('text_1_home') }}
                                    </v-card-text>
                                </div>
                                <div class="d-flex justify-center">
                                    <v-card-text
                                        class="textHome white--text mx-xl-2 text-xl-h5 text-caption d-flex pl-lg-6 pl-xl-8 pt-3"
                                        :class="[$vuetify.breakpoint.xl ? 'font-weight-semibold' : 'font-weight-semibold']"
                                    >{{ $t('text_2_home')}}</v-card-text>
                                </div>
                                <div class="d-flex justify-start">
                                    <v-card-text class="pl-lg-6 ml-xl-2 pt-lg-4 pt-xl-8 text-xl-body-1"
                                        style="color: #14aa95">
                                        {{ $t('text_3_home') }}
                                    </v-card-text>
                                </div>
                            </div>
                        </div>
                    </v-card>
                    <div class="mt-10 mx-16 px-16">
                        <v-row>
                            <v-col cols="4">
                                <v-card class="rounded-card" dark max-width="344"
                                    :height="$vuetify.breakpoint.lgAndUp ? ($vuetify.breakpoint.xl ? '250' : '180') : 'none'">
                                    <v-card-title class="text-xl-h4 text-h5 font-weight-bold">Total
                                        Investido</v-card-title>
                                    <v-card-text class="white--text text-xl-h3 text-lg-h4 pb-xl-10">{{ formatarValorMonetario(total_investido) }}</v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="4">
                                <v-card class="rounded-card-2" dark max-width="344"
                                    :height="$vuetify.breakpoint.lgAndUp ? ($vuetify.breakpoint.xl ? '250' : '180') : 'none'">
                                    <v-card-title class="text-xl-h4 text-h5 font-weight-bold">Total a
                                        receber</v-card-title>
                                    <v-card-text class="white--text text-xl-h3 text-lg-h4 pb-xl-10">{{ formatarValorMonetario(total_receber) }}</v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="4">
                                <v-card class="rounded-card-3" max-width="344"
                                    :height="$vuetify.breakpoint.lgAndUp ? ($vuetify.breakpoint.xl ? '250' : '180') : 'none'">
                                    <v-card-title class="text-xl-h5 text-lg-subtitle-1 d-flex font-weight-bold"
                                        style="color: #14aa95">Rodada de
                                        investimentos <br> em aberto!</v-card-title>
                                    <v-img style="cursor:pointer" @click="$router.push('/business')"
                                        class="ml-6 d-flex justify-center align-center"
                                        :max-width="$vuetify.breakpoint.mdAndUp ? ($vuetify.breakpoint.xl ? '170' : '120') : 'none'"
                                        src="../../assets/layer2.png">
                                        <div>
                                            <span
                                                class="ml-xl-4 ml-lg-2 white--text text-xl-subtitle-2 text-lg-caption">Clique
                                                e confira</span>
                                        </div>
                                    </v-img>
                                </v-card>
                            </v-col>

                        </v-row>
                    </div>
                </v-col>
            </template>
        </layout>
    </div>
</template>

<script>
import layout from "../../layouts/internalLayout.vue";
import axios from "axios";
export default {
    metaInfo() {
    return {
      title: 'BELat - Home'
    }
  },
    components: {
        layout,
    },
    data() {
        return {
            user: {
                name: null,
                email: null,
                phone_number: null,
                profile_photo_url: null,
            },
            token: localStorage.getItem("token"),
            urlEnv: process.env.VUE_APP_SERVICE_URL,
            total_investido: 0,
            total_receber: 0
        };
    },
    methods: {
        async getInvestidor() {
            const config = {
                headers: { Authorization: `Bearer ${this.token}` },
            };
            axios.get(this.urlEnv + `getInvestidor`, config)
                .then((response) => {
                    console.log(response);
                    this.total_investido = response.data.data.total_investido;
                    this.total_receber = response.data.data.total_receber;
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        formatarValorMonetario(valor) {
            // Verifica se o valor é válido antes de formatar
            if (valor !== undefined && valor !== null && !isNaN(valor)) {
                const formatter = new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL', // Defina a moeda como BRL para reais brasileiros
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                });
                return formatter.format(valor);
            } else {
                return 'R$ 0,00';
            }
        }
    },
    created() {
        this.getInvestidor();
    },
    computed: {
    },
};
</script>

<style scoped>
.v-navigation-drawer__border {
    display: none;
}

.rounded-card {
    border-radius: 20px;
    background: #032a5a
}

.rounded-card-2 {
    border-radius: 20px;
    background: #DF6E8B
}

.rounded-card-3 {
    border-radius: 20px;
    border: 2px solid #14aa95;
}

.cdc-overlay {
    background: transparent !important;
}

.dashboard {
    border-radius: 20px;
    border: 1px solid #DF6E8B;
}

.month {
    background: #032a5a;
    border-radius: 10px;
}

.currency {
    background: rgba(128, 128, 128, 0.192);
}

.text-h4,
.text-xl-h4,
.text-xl-h5,
.text-h3,
.text-h5 .text-h6,
.white--text,
.text-center,
.d-flex {
    font-family: 'Raleway', cursive !important;
}

@media (min-width: 2560px) {
    .config {
        font-size: 24px;
    }
}

@media (min-width: 1920px) and (max-width: 2559px) {
    .config {
        font-size: 17px;
    }
}

@media (min-width: 1280px) and (max-width: 1919px) {
    .config {
        font-size: 9.5px;
    }
}

@media (max-width: 1279px) {
    .config {
        font-size: 12px;
    }
}</style>
