<template>
  <div>
    <layout style="z-index: 100; position: relative;display: flex; flex-direction: column; min-height: 100vh;">
      <template v-slot:content>   
        
        <div class="mt-lg-6 mt-sm-1 mx-4 py-auto px-sm-10 px-xs-2">
          <v-row>
            <v-col class="col-xs-12 col-sm-6">
                  <v-img src="../../assets/belat_app_02.png" width="100%" max-width="500px" min-width="350px" style="object-fit: contain;" class="mx-auto">
                      <v-row class="align-end d-flex justify-center pt-4 px-6" style="height:90%;">
                          <div class="px-16 px-xs-5">
                              <span class="text-h5 white--text font-weight-bold">CROWDLENDING</span>
                            <p class="mb-1 white--text textHome2">Investimento de impacto coletivo direto em uma empresa
                            </p>
                            <v-row class="d-flex justify-center align-end textHome">
                                <v-btn @click="$router.push('/business')" rounded class="d-flex justify-end py-6 mt-10 textHome" color="primary"
                                  dark >
                                  <span>Invista AGORA EM IMPACTO POSITIVO</span>
                                  <v-list-item-avatar size="30" class="opacityButton d-flex align-end ">
                                    <v-icon>mdi-chevron-right</v-icon>
                                  </v-list-item-avatar>
                                </v-btn>
                            </v-row>
                              
                          </div>
                    </v-row>
                  </v-img>
            </v-col>
            <v-col cols="6" class="col-xs-12">
              <v-img src="../../assets/belat_app_01.png" width="100%" max-width="500px" min-width="350px" style="object-fit: contain;" class="m-auto">
                  <v-row class="align-end d-flex justify-center pt-4" style="height:90%;">
                      <div class="px-16">
                          <span class="text-h5 text-xs-h6 white--text font-weight-bold">FUNDO BELA IMPACTO <br>CRÉDITO CONSCIENTE </span>
                        <span class="text-center white--text textHome2" >Investimento em fundo de Direitos Creditórios (FIDC)
                        </span>
                        <p class="mb-5 white--text" style="font-size:12px">*disponível para investidores qualificados</p>
                        <v-row class="d-flex justify-center align-end">
                            <v-btn @click="$router.push('/FIDC')" rounded class="d-flex justify-end py-6" style="min-width:100%" color="primary"
                              dark >
                              <span class="mr-5 mr-xs-1 textHome">CONHEÇA e faça parte</span>
                                <v-list-item-avatar size="30" class="opacityButton2 ml-10">
                                  <v-icon>mdi-chevron-right</v-icon>
                                </v-list-item-avatar>
                            </v-btn>
                          </v-row>
                          
                      </div>
                </v-row>
              </v-img>
        </v-col>
          </v-row>
      </div>   
      <div class="teste" style="background: #032A5A; width: 100%;">
          <div class="pa-2 d-flex justify-center align-center">
              <h1 class="text-h6 white--text text-center">Quer participar do desenvolvimento da <br>
   Banca Ética Latinoamericana? <span class="secondary--text">Fale aqui com a gente.</span></h1>
          </div>
      </div>     
  </template>
</layout>
  </div>
</template>

<script>
import layout from "../../layouts/internalLayout.vue";
import axios from "axios";
export default {
  metaInfo() {
  return {
    title: 'BELat - Home'
  }
},
  components: {
    layout,
  },
  data() {
    return {
      tabs: null,
      tabNames: ['Dados pessoais', 'Informações adicionais', 'Declarações'],
      text: '',
      userId: localStorage.getItem("userId"),
      user: {
        name: null,
        email: null,
        phone_number: null,
        profile_photo_url: null,
      },
      currentScreenIndex: 0,
      token: localStorage.getItem("token"),
      urlEnv: process.env.VUE_APP_SERVICE_URL,
      items: [
        {
          title: "Meus Investimentos",
          icon: "mdi-home-city",
          route: "/user/MyInvestiments",
        },
        { title: "Meus dados", icon: "mdi-account", route: "/user/MyInfo" },
      ],
      right: null,
    };
  },
  methods: {
    async getUserData() {
      const config = {
        headers: { Authorization: `Bearer ${this.token}` },
      };
      axios.get(this.urlEnv + "user", config).then(response => {
        // if(response.)
        // console.log(response)
        let userData = response.data;
        this.user.name = userData.name;
        this.user.email = userData.email;
        this.user.phone_number = userData.phone_number;
        this.user.profile_photo_url = userData.profile_photo_url;
      }).catch(error => {
        console.error(error);
      })
    },
    setCurrentScreen(index) {
      this.currentScreenIndex = index;
    },
  },
  created() {
    // this.checkUser()
    this.getUserData();
  },
  computed: {
    currentScreen() {
      return this.screens[this.currentScreenIndex];
    },
    totalScreens() {
      return this.screens.length;
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
};
</script>

<style scoped>
.v-navigation-drawer__border {
  display: none;
}

.rounded-card {
  border-radius: 20px;
  border: 1px solid #032a5a
}

.rounded-card-2 {
  border-radius: 20px;
  border: 1px solid #DF6E8B
}

.rounded-card-3 {
  border-radius: 20px;
  border: 1px solid #14aa95;
}

.opacityButton{
  background: #14aa95;
}

.opacityButton2 {
  background: #DF6E8B;
}

.oi {
  border-bottom: 10px solid black;
}

.text-h4,
.text-xl-h4,
.text-xl-h5,
.text-h3,
.text-h5 .text-h6,
.white--text,
.text-center,
.d-flex {
    font-family: 'Raleway', cursive !important;
}

@media (min-width: 2560px) {
  .textHome {
    font-size: 24px; 
  }
  .textHome2 {
    font-size: 21px; 
  }
}
@media (min-width: 1920px) and (max-width: 2559px) {
  .textHome {
    font-size: 17px !important; 
  }
  .textHome2 {
    font-size: 21px; 
  }
}
@media (min-width: 1280px) and (max-width: 1919px) {
  .textHome {
    font-size: 14px; 
  }
  .textHome2 {
    font-size: 21px; 
  }
}
@media (max-width: 1279px) {
  .textHome {
    font-size: 12px; 
  }
  .textHome2 {
    font-size: 16px; 
  }
}

/* .teste {
  position: absolute;
  bottom: 0;
} */
</style>