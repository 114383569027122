<template>
  <div>
    <div class="py-6 position-relative">
      <v-icon class="custom-icon" @click="navigateBack">mdi-chevron-left</v-icon>
      <div class="text-center" style="color:#032A5A;font-size: 18px;font-weight: 700;">{{ sectionTitle }}</div>
    </div>
    <div v-if="section === 'dados-pessoais'">
      <!-- Conteúdo para Dados Pessoais -->
      <PersonalInfo />
    </div>
    <div v-else-if="section === 'informacoes-adicionais'">
      <!-- Conteúdo para Informações Adicionais -->
      <JobPage />
    </div>
    <div v-else-if="section === 'endereco'">
      <!-- Conteúdo para Endereço -->
      <AddressPage />
    </div>
    <div v-else-if="section === 'dados-bancarios'">
      <!-- Conteúdo para Dados Bancários -->
      <AccountDetail />
    </div>
    <div v-else-if="section === 'meus-documentos'">
      <!-- Conteúdo para Meus Documentos -->
      <MyDocs />
    </div>
  </div>
</template>

<script>
import PersonalInfo from "./PersonalInfo.vue";
import MyDocs from "./MyDocs.vue";
import AccountDetail from "./AccountDetail.vue";
import JobPage from "./JobPage.vue";
import AddressPage from "./AddressPage.vue";

export default {
  props: ['section'],
  components: {
    AddressPage,
    PersonalInfo,
    JobPage,
    AccountDetail,
    MyDocs,
  },
  computed: {
    sectionTitle() {
      const titles = {
        'dados-pessoais': 'Dados Pessoais',
        'informacoes-adicionais': 'Informações Adicionais',
        'endereco': 'Endereço',
        'dados-bancarios': 'Dados Bancários',
        'meus-documentos': 'Meus Documentos'
      };
      return titles[this.section] || 'Informações';
    }
  },
  methods: {
    navigateBack() {
      this.$router.go(-1); // Navega para a página anterior
    }
  }
};
</script>

<style scoped>
.custom-icon {
  color: #032A5A;
  font-size: 24px;
  position: absolute;
  left: 16px;
  cursor: pointer; /* Adiciona cursor de ponteiro para indicar que o ícone é clicável */
}
.position-relative {
  position: relative;
}
</style>
