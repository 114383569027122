<template>
    <v-main style="background: rgb(249 250 251); height: 100vh; padding: 0;">
        <v-container class="custom-container mt-16" style="display: flex;border: 0px;justify-content: center;align-items: center; width: 100vw; height: 90vh;" fluid>
            <v-app-bar fixed color="white" style="z-index: 99;">
                <v-img max-width="150" src="../../assets/belat.png"></v-img>
                <v-spacer></v-spacer>

            </v-app-bar>
            <v-card elevation="5" class="white"
            style="z-index: 10; border-radius: 5%;"
        :class="{ 'lg-width-50': $vuetify.breakpoint.lgAndUp, 'xs-width-100': $vuetify.breakpoint.xs }">
                <div class="pa-16">

                    <v-row class="text-center">
                        <v-col cols="12">
                            <v-icon color="secondary" size="70">
                                mdi-email-check
                            </v-icon>
                            <h2 class="text-start primary--text font-weight-bold">
                                Parabéns, conta verificada com sucesso!
                            </h2>
                            <div class="oi"></div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class=" text-start">
                            <p class="text-start primary--text">
                                Seu email foi verificado e agora você pode acessar sua conta
                            </p>
                            <p class="primary--text">
                                Clique no botão abaixo para fazer login
                            </p>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="px-16 d-flex justify-center">
                            <v-hover v-slot="{ hover }">
                                <v-btn rounded block :class="{ 'on-hover': hover }"
                                    class="pa-6 px-16 transition-fast-in-fast-out"
                                    :color="hover ? 'primary' : 'secondary'" href="/">
                                    <span>Fazer login</span>
                                </v-btn>
                            </v-hover>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
        </v-container>
    </v-main>
</template>

<script>
    import axios from "axios";

    export default {
        data() {
            return {
                urlEnv: process.env.VUE_APP_SERVICE_URL,
            };
        },
        mounted() {
            axios.post(`${this.urlEnv}email/verify`, {
                id: this.$route.params.id,
                hash: this.$route.params.hash
            },
            {
                headers: {'X-Frontend-URL': window.location.origin}
            })
            .then(response => {
                console.log(response.data); 
            })
            .catch(error => {
                console.error(error);
            });
        }
    };
</script>

<style scoped>
  .custom-container {
    max-width: 1440px;
    margin: 0 auto;
    border: 1px solid #fff;
    padding: 20px;
  }

.gifLogo {
    position: absolute;
    top: -30%;
    left: 27%;
    bottom: 2%;
    max-width: 40%;
}

.v-main__wrap {
    background: rgb(249 250 251) !important;
}

.oi {
    width: 7.5rem;
    border: 1px solid #14aa95;
    height: 2px;
}

.text-h4,
.text-xl-h4,
.text-xl-h5,
.text-h3,
.text-h5 .text-h6,
.white--text,
.text-center,
.d-flex {
    font-family: 'Raleway', cursive !important;
}

.lg-width-50 {
    width: 50%;
}

.xs-width-100 {
    width: 100%;
}

/* SCSS */
@include breakpoint(lg) {
    .lg-width-50 {
        width: 50%;
    }
}

@include breakpoint(xs) {
    .xs-width-100 {
        width: 100%;
    }
}
</style>