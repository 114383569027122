import { render, staticRenderFns } from "./impactProfileTwo.vue?vue&type=template&id=18acec1c&scoped=true"
import script from "./impactProfileTwo.vue?vue&type=script&lang=js"
export * from "./impactProfileTwo.vue?vue&type=script&lang=js"
import style0 from "./impactProfileTwo.vue?vue&type=style&index=0&id=18acec1c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18acec1c",
  null
  
)

export default component.exports