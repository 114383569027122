<template>
    <v-main style="background: rgb(249 250 251); height: 100%;">
        <v-container class="custom-container" style="height: 100%; padding: 0;">
            <v-main style="height: 100%; background-color: #DF6E8B;">
                <v-row style="height:100%;">
                    <v-col cols="12" md="7" class="primary d-flex flex-column justify-center position-relative">
                        <div class="d-flex justify-end">
                            <v-img class="gifLogo" src="../../assets/gif_banca.gif"></v-img>
                        </div>
                        <!-- Texto de background -->
                        <div class="col-9">
                            <div class="d-flex align-start h-100 flex-column pl-16 mt-16" style="position: relative;">
                                <v-img class="mb-5" max-width="250" src="../../assets/banca_etica.png"></v-img>
                                <h3 class="pt-10 font-weight-600 white--text">Olá, <br /> que bom ver você aqui.</h3>
                                <h1 class="custom-letter-spacing mt-5 text-subtitle-1 font-weight-light white--text mb-3">Aqui, na plataforma de investimento da 
                                <b><span>BELAT</span></b>, você pode conectar seus investimentos com seus valores, e gerar um impacto socioambiental positivo em qualquer operação de sua escolha.
                                </h1>
                                <h1 class="custom-letter-spacing mt-2 text-subtitle-1 font-weight-light white--text mb-3">Impacto é uma questão de escolha. 
                                Venha conosco e faça a sua.</h1>
                                <!-- <h1 class="custom-letter-spacing mt-2 text-subtitle-1 font-weight-light white--text mb-3">
                                    Gera impacto
                                    positivo. Gera saúde, gera possibilidade, gera preservação.</h1> -->
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="5" lx="5" class="white"
                        style="z-index: 10; display: flex; justify-content: center; align-items: center;">
                        <div style="width: 60%">

                            <v-row class="text-center">
                                <v-col cols="12">     
                                    <p class="text-center text-h5 primary--text">
                                        Digite a nova senha
                                    </p>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" class="d-flex justify-center">
                                    <span class="text-left blue-grey--text text--darken-1">
                                        <p class="mb-2">
                                            Digite sua nova senha. Lembre-se de que ela deve ter:
                                        </p>
                                        <ul>
                                            <li class="text-left">Um minimo de 8 caracteres</li>
                                            <li class="text-left">Incluir pelo menos um caractere especial</li>
                                            <li class="text-left">Conter uma letra maiúscula</li>
                                        </ul>
                                    </span>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field v-on:keyup.enter="submit" :append-icon="showPass1 ? 'mdi-eye' : 'mdi-eye-off'"
                                        @click:append="showPass1 = !showPass1" :type="showPass1 ? 'text' : 'password'" class="rounded-lg"
                                        label="Digite sua nova senha" :rules="rules" hide-details="auto" name="password" v-model="password" outlined
                                        dense>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field v-on:keyup.enter="submit" :append-icon="showPass2 ? 'mdi-eye' : 'mdi-eye-off'"
                                        @click:append="showPass2 = !showPass2" :type="showPass2 ? 'text' : 'password'" class="rounded-lg"
                                        label="Digite novamente sua senha" :rules="rulesConfirm" hide-details="auto" name="confirmPassword" v-model="confirmPassword" outlined
                                        dense>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" class="d-flex justify-center">
                                    <v-hover v-slot="{ hover }">
                                        <v-btn rounded block :class="{ 'on-hover': hover }"
                                            class="pa-6 px-16 transition-fast-in-fast-out"
                                            :color="hover ? 'secondary' : 'primary'" @click="submit">
                                            <span>Atualização</span>
                                        </v-btn>
                                    </v-hover>
                                </v-col>

                                <v-col cols="12" class="d-flex justify-center">
                                    <span class="text-center blue-grey--text text--darken-1">
                                        Deseja entrar?
                                        <a class="text-decoration-none secondary--text " href="/">
                                            Clique aqui
                                        </a>
                                    </span>
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>
                </v-row>
            </v-main>
        </v-container>
    </v-main>
</template>
  
<script>
import axios from "axios";

export default {
    data() {
        return {
            errorMessageMap: {
                password: "O campo senha é obrigatorio",
                confirmPassword: "O campo confirmação de senha é obrigatório"
            },
            urlEnv: process.env.VUE_APP_SERVICE_URL,
            password: null,
            confirmPassword: null,
            showPass1: false,
            showPass2: false,
            rules: [
                (v) => !!v || "Campo obrigatório",
                (v) =>
                    (v && v.length >= 8) || "A senha deve ter pelo menos 8 caracteres",
                (v) =>
                    (v && /[A-Z]/.test(v)) ||
                    "A senha deve conter pelo menos uma letra maiúscula",
                (v) =>
                    (v && /[a-z]/.test(v)) ||
                    "A senha deve conter pelo menos uma letra minúscula",
                (v) =>
                    (v && /\d/.test(v)) || "A senha deve conter pelo menos um número",
            ],
            rulesConfirm: [
                (v) => !!v || "Campo obrigatório",
                (v) => v === this.password || "As senhas não coincidem"
            ]
        };
    },
    created() {
        if (!this.$route.params.token || !this.$route.params.email) {
            this.$router.push('/');
        }
    },
    methods: {
        submit() {
            const token = this.$route.params.token;
            const email = this.$route.params.email;
            const fields = ["password", "confirmPassword"];
            for (const field of fields) {
                if (!this[field]) {
                    this.$toast.error(this.errorMessageMap[field]);
                    return;
                }
            }
            axios
                .post(this.urlEnv + `resetPassword/${token}`, {
                    email: email,
                    password: this.password,
                    password_confirmation: this.confirmPassword
                })
                .then((response) => {
                    this.$toast.success(response.data.mensagem);
                    this.$router.push('/');
                })
                .catch((err) => {
                    if (err.response && err.response.data && err.response.data.mensagem) {
                        this.$toast.error(err.response.data.mensagem);
                    } else {
                        console.error(err);
                        this.$toast.error("Token expirado");
                    }
                });
        },
    },
};
</script>
  
<style scoped>
.custom-container {
    max-width: 1440px;
    margin: 0 auto;
    border: 1px solid #fff;
}

.gifLogo {
    position: absolute;
    top: -30%;
    left: 27%;
    bottom: 2%;
    max-width: 40%;
}

/*
  @media screen and (max-width: 1366px) {
      .teste {
          bottom: 8%;
      }
  }
  
  @media screen and (max-width: 1350px) {
      .teste {
          left: 15%; 
          bottom: 22%;
          max-width: 659px;
      }
  }
  
  @media screen and (max-width: 1930px) {
      .teste {
          left: 23%;
      }
  }
  
  @media screen and (max-width: 2000px) {
      .teste {
          bottom: 15%;
      }
  }
  */

.v-main__wrap {
    background: rgb(249 250 251) !important;
}

.text-h4,
.text-xl-h4,
.text-xl-h5,
.text-h3,
.text-h5 .text-h6,
.white--text,
.text-center,
.d-flex {
    font-family: 'Raleway', cursive !important;
}</style>
  