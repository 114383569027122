<template>
    <div class="filter-component">
        <div class="dropdown">
            <button @click="toggleDropdown" class="dropdown-button">Filtro ▼</button>
            <div v-if="showDropdown" class="dropdown-content">
                <div class="headerText d-flex justify-space-between">
                    <h3 class="text-body-1">Filtrar por:</h3>
                    <button class="text-body-2" style="color: #00265b" @click="limparFiltros">Limpar filtros</button>
                </div>
                <div class="filter-category">
                    <div class="mb-3">
                        <h4 class="text-body-2 primary--text d-inline">Status Do Investimento</h4>
                    </div>
                    <div v-for="status in statusDoInvestimento" :key="status.id">
                        <input type="checkbox" :id="'status_' + status.value" v-model="filtrosSelecionados.status"
                            :value="status.value">
                        <label :for="'status_' + status.value" class="ml-2 text-caption" style="color: #00265b">{{
                status.text }}</label>
                    </div>
                </div>
                <div class="filter-category">
                    <div class="mb-3">
                        <h4 class="text-body-2 primary--text d-inline">Áreas De Investimento</h4>
                    </div>
                    <div v-for="area in areasDeInvestimento" :key="area.id">
                        <input type="checkbox" :id="'area_' + area.value" v-model="filtrosSelecionados.areas"
                            :value="area.value">
                        <label :for="'area_' + area.value" class="ml-2 text-caption" style="color: #00265b">{{ area.text
                            }}</label>
                    </div>
                </div>
                <div class="filter-category">
                    <div class="mb-3">
                        <h4 class="text-body-2 primary--text d-inline">Classificação dos Investimentos</h4>
                    </div>
                    <div v-for="classificacao in classificacaoInvestimentos" :key="classificacao.id">
                        <input type="checkbox" :id="'classificacao_' + classificacao.value"
                            v-model="filtrosSelecionados.classificacoes" :value="classificacao.value"
                            @change="keepOne(classificacao.value)">
                        <label :for="'classificacao_' + classificacao.value" class="ml-2 text-caption"
                            style="color: #00265b">{{ classificacao.text }}</label>
                    </div>
                </div>
                <div class="text-end">
                    <button class="text-body-2" style="color: #00265b" @click="aplicarFiltros">Aplicar</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        businesses: {
            type: Array,
            default: () => []
        },
        areasDeInvestimento: Array
    },
    data() {
        return {
            statusDoInvestimento: [
                { id: 1, text: 'Aberto', value: 2 },
                { id: 2, text: 'Em Andamento', value: 3 },
                { id: 3, text: 'Encerrado', value: 5 }
            ],
            classificacaoInvestimentos: [
                { id: 1, text: 'Do Mais Recente', value: 'recente' },
                { id: 2, text: 'Do Mais Antigo', value: 'antigo' },
            ],
            filtrosSelecionados: {
                status: [],
                areas: [],
                classificacoes: []
            },
            showDropdown: false
        };
    },
    methods: {
        limparFiltros() {
            this.filtrosSelecionados = { status: [], areas: [], classificacoes: [] };
        },
        aplicarFiltros() {
            let filteredBusinesses = [...this.businesses];

            const statusIds = this.filtrosSelecionados.status.map(status => {
                return status === 3 ? [3, 4] : [status];
            }).flat();

            if (statusIds.length > 0) {
                filteredBusinesses = filteredBusinesses.filter(business => {
                    return statusIds.includes(business.status_id);
                });
            }

            if (this.filtrosSelecionados.areas.length > 0) {
                filteredBusinesses = filteredBusinesses.filter(business => {
                    return this.filtrosSelecionados.areas.includes(business.neg_area_id);
                });
            }

            filteredBusinesses.sort((a, b) => {
                if (this.filtrosSelecionados.classificacoes.includes('recente')) {
                    return new Date(b.data_liberacao) - new Date(a.data_liberacao);
                }
                if (this.filtrosSelecionados.classificacoes.includes('antigo')) {
                    return new Date(a.data_liberacao) - new Date(b.data_liberacao);
                }
                return 0;
            });
            this.showDropdown = !this.showDropdown;
            this.$emit('filtrarBusinesses', filteredBusinesses);
        },
        toggleDropdown() {
            this.showDropdown = !this.showDropdown;
        },
        keepOne(checkedValue) {
            this.filtrosSelecionados.classificacoes.forEach((value, index) => {
                if (value !== checkedValue) {
                    this.filtrosSelecionados.classificacoes.splice(index, 1);
                }
            });
        }
    }
};
</script>

<style scoped>
/* Aqui viriam os estilos para o seu componente de filtro, como no exemplo da sua imagem */
.dropdown {
    position: relative;
    display: inline-block;
}

.headerText {
    margin-bottom: 1.2rem;
}

.text-body-2.primary--text {
    position: relative;
}

.text-body-2.primary--text::after {
    content: '';
    position: absolute;
    bottom: -2px;
    /* Ajuste conforme necessário */
    left: 0;
    width: 100%;
    height: 0.1rem;
    /* Espessura da linha */
    background-color: rgb(204, 63, 63);
    /* Cor da linha */
}

.dropdown-button {
    background-color: #f8f9fa;
    border: 1px solid #00265b;
    border-radius: 4px;
    padding: 0.3rem 0.6rem;
    cursor: pointer;
    color: #00265b;
}

.dropdown-button:hover {
    background-color: #e9ecef;
}

.dropdown-content {
    border-radius: 2%;
    border: 1px solid rgba(0, 0, 0, 0.144);
    /* estilos para o conteúdo do dropdown */
    display: none;
    right: 10;
    position: absolute;
    background-color: white;
    padding: 1.5rem;
    width: 280px;
    min-width: 160px;
    z-index: 1;
}

/* Mostra o dropdown quando o botão é clicado */
.dropdown .dropdown-content {
    display: block;
}

/* Estilos adicionais conforme necessário */
</style>