<template>
    <layout>
      <template v-slot:content>
                <div class="d-flex flex-column items-center primary">
              <div class="px-6 py-3 d-flex align-center" style="border-bottom: 1px solid #14aa95;">
                <div class="d-flex ml-16">
                  <v-list-item-avatar class="ml-2" size="60">
                    <v-img :src="user.profile_photo_url"></v-img>
                  </v-list-item-avatar>
                  <div class="text-h4 font-weight-medium text-center white--text " style="display: flex; align-items: center; font-family: 'Raleway'"> 
                    {{ user.name }}
                  </div>
                </div>
          </div>
          <div class="d-flex align-start pa-4 px-16">
            <div class="d-flex flex-column" style="width:40%">
              <b class="white--text font-weight-medium" style="font-family: 'Raleway';"> E-mail</b> 
              <v-text-field append-icon="mdi-alert-circle-outline" outlined dark v-model="user.email" class="w-full" disabled></v-text-field>
            </div>
            <!-- <div style="width: 30%;">
              <v-btn rounded class="transition-fast-in-fast-out " style="
                  width: 100%;
                  background: transparent;
                  border: 1px solid #7ba8cd;
              ">
                <span style="color:white">Investir em outro país</span>
              </v-btn>
            </div> -->
          </div>
        </div>

              <div class="mt-0">
                <v-tabs fixed-tabs v-model="tabs" color="secondary" centered>
            <v-tab v-for="n in 5" :key="n">
              {{ tabNames[n-1] }}
            </v-tab>
          </v-tabs>
                
              </div>

              <v-tabs-items v-model="tabs" >
            <v-tab-item>
              <v-card flat class="d-flex flex-column align-center w-100">
                <v-card-text style="width: 80%">
                <PersonalInfo />
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <JobPage />
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                
                <v-card-text>
                  <AddressPage />
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                
                <v-card-text>
                  <AccountDetail />
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                
                <v-card-text>
                  <MyDocs />
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
                <!-- <v-list dense nav>
                  <v-list-item
                    v-for="(item, index) in campos"
                    :key="item.title"
                    link
                    @click="setCurrentScreen(index)"
                  >
                    <v-list-item-icon>
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list> -->
              <!-- <div v-if="!isMobile" class="mt-5" style="width: 100%">
                <component :is="currentScreen" ref="formScreens" />
              </div>
            <div v-if="isMobile" class="mt-5" style="width: 100%">
              <component :is="currentScreen" ref="formScreens" />
            </div> -->
          </template>
        </layout>
</template>

<script>
import layout from "../../layouts/internalLayout.vue";
import AccessPage from "./Pages/AccessPage.vue";
import PersonalInfo from "./Pages/PersonalInfo.vue";
import MyDocs from "./Pages/MyDocs.vue";
import AccountDetail from "./Pages/AccountDetail.vue";
import JobPage from "./Pages/JobPage.vue";
import Address from "./Pages/AddressPage.vue";
import AddressPage from "./Pages/AddressPage.vue";
import axios from "axios";
export default {
  metaInfo() {
    return {
      title: 'BELat - Minhas Informações'
    }
  },
  components: {
    AddressPage,
    PersonalInfo,
    JobPage,
    AccountDetail,
    layout,
    MyDocs,
  },
  data() {
    return {
      tabs: this.$route.query.renderOnTabFour ? 4 : null,
      tabNames: ['Dados pessoais', 'Informações adicionais', 'Endereço', 'Dados bancários', 'Meus documentos'],
      text: '',
      userId: localStorage.getItem("userId"),
      screens: [
        AccessPage,
        PersonalInfo,
        AccountDetail,
        JobPage,
        Address,
      ],
      user: {
        name: null,
        email: null,
        phone_number: null,
        profile_photo_url: null,
      },
      currentScreenIndex: 0,
      token: localStorage.getItem("token"),
      urlEnv: process.env.VUE_APP_SERVICE_URL,
      items: [
        {
          title: "Meus Investimentos",
          icon: "mdi-home-city",
          route: "/user/MyInvestiments",
        },
        { title: "Meus dados", icon: "mdi-account", route: "/user/MyInfo" },
      ],
      right: null,
    };
  },
  methods: {
        checkUser() {
      const config = {
        headers: { Authorization: `Bearer ${this.token}` },
      };
      axios
        .get(this.urlEnv + `checkCadastro`, config)
        .then((response) => {
          if(response.data.status == false) {
            this.$router.replace('/templateRegister');
          }
        })
        .catch((error) => {
          console.log(error);
          false;
        });
    },
    async getUserData() {
      const config = {
        headers: { Authorization: `Bearer ${this.token}` },
      };
        axios.get(this.urlEnv + "user", config).then(response => {
          // if(response.)
          console.log(response)
          let userData = response.data;
          this.user.name = userData.name;
          this.user.email = userData.email;
          this.user.phone_number = userData.phone_number;
          this.user.profile_photo_url = userData.profile_photo_url;
          console.log(userData);
        }).catch (error => {
          console.error(error);
    })
    },
    setCurrentScreen(index) {
      this.currentScreenIndex = index;
    },
  },
  mounted() {
    this.checkUser()
    this.getUserData();
  },
  computed: {
    currentScreen() {
      return this.screens[this.currentScreenIndex];
    },
    totalScreens() {
      return this.screens.length;
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
};
</script>

<style scoped>

.v-navigation-drawer__border {
  display: none;
}

</style>