<template>
  <div class="custom-container">
    <v-row style="padding: 0; height: 100vh">
      <v-app-bar fixed color="white" style="z-index: 99;">
        <v-img max-width="150" src="../../../assets/belat.png"></v-img>
        <v-spacer></v-spacer>

      </v-app-bar>
      <v-app-bar fixed color="#032A5A"
        style="height: 10px; margin-top: 0px; transform: translateY(0px); top: auto !important; left: 0px; right: 0px; z-index: 99; bottom: 0px;">

      </v-app-bar>
      
      <v-app-bar fixed color="#032A5A"
                style="height: 10px; margin-top: 0px; transform: translateY(0px); top: auto !important; left: 0px; right: 0px; z-index: 99; bottom: 0px;">
            </v-app-bar>
      <!-- <v-col cols="12" class="mt-12" :style="{
        overflowY: 'auto',
        // maxHeight: '600px',
        backgroundImage: 'url(' + require('@/assets/imageInicial2.png') + ')',
        backgroundSize: 'contain',
        backgroundPositionX: '140%',
        padding: '0'
      }"> -->
      <v-col cols="12" class="pa-0 ma-0 mt-12 col-xl-3 col-lg-4 col-sm-6 col-xm-12">
        <div class="mt-2">
          <timeline class="ml-xl-14 ml-lg-10 ml-sm-5 " :items="currentTimeline" :activeIndex="currentPosition" />
        </div>
        <div class="ml-xl-14 ml-lg-10 ml-sm-5 black--text">
          <h5 class="secondary--text text-h5 font-weight-bold">PERFIL DE <br> IMPACTO</h5>
          <p class="mb-5 text-regular">Descubra quais setores de impacto socioambiental você gostaria de fomentar</p>
          <h5 class="secondary--text text-h5 font-weight-bold">Queremos lhe apresentar as melhores opções de
            investimento!</h5>
          <p class="mt-5 text-regular">Para validar sua conta de investidor(a), precisamos lhe conhecer um pouco mais. </p>
            
          <p class="text-regular">Clique no botão abaixo e
            responda. <br>
            Serão só 5 minutos</p>
          <p class="mt-5 pb-1 text-regular black--text">Precisamos entender suas metas financeiras, seu perfil de
            investidor(a) e quais os negócios de impacto estão de
            acordo com os seus valores. Vamos lá?</p>
          <div class="d-flex justify-center">
            <v-btn class="px-14 py-6 font-weight-bold" style="width: 80%;" color="secondary" @click="startQuestions" dark
              rounded>Começar</v-btn>
            </div>
            <div class="d-flex justify-center">
                <span style="text-decoration: underline; color: gray;" @click="redirectHome" class="mt-3 font-weight-normal">
                  VER OPORTUNIDADES
                </span>
            </div>
        </div>
      </v-col>
      <v-col cols="8" class="mt-12 col-xl-9 col-lg-8 col-sm-6 custom-background" :style="{
        overflowY: 'auto',
        // maxHeight: '600px',
        // backgroundImage: 'url(' + require('@/assets/imageInicial2.png') + ')',
        // backgroundSize: 'cover',
        // backgroundPositionX: '140%',
        padding: '0'
      }">
        
      </v-col>
      <!-- <v-col cols="6" class="mt-10">
          <v-img src="../../../assets/imageInicial.png" cover width="100%"></v-img>
      </v-col> -->
    </v-row>
  </div>
</template>




<script>
import timeline from "../Components/TimeLine.vue"
import axios from "axios";
export default {
  props: {
    currentPosition: Number,
    currentTimeline: Array,
    currentScreenIndex: Number,
  },
  components: {
    timeline
  },
  data() {
    return {
      currentStage: 0,
      userId: localStorage.getItem("userId"),
      cnpj_cpf: null,
      dialog: true,
      token: localStorage.getItem("token"),
      urlEnv: process.env.VUE_APP_SERVICE_URL,
      titularUser: {},
    };
  },
  methods: {
    redirectHome() {
      this.$router.push({ name: "HomePage" });
    },
    backPage() {
      this.$parent.goToPreviousScreen();
    },
    getColor(position) {
      return position <= this.currentPosition ? 'secondary' : 'gray';
    },
    getImageHeight() {
      if (this.$vuetify.breakpoint.width >= 1280) {
        return this.$vuetify.breakpoint.width >= 1920 ? 500 : 250;
      } else {
        return 250;
      }
    },
    startQuestions() {
      this.$parent.goToNextScreen();
    },
    async getInvestidor() {
      const config = {
        headers: { Authorization: `Bearer ${this.token}` },
      };
      try {
        axios
          .get(this.urlEnv + `getInvestidor`, config)
          .then((response) => {
            this.titularUser = response.data.data.investidor;
            console.log(this.titularUser);
          })
          .catch((error) => {
            console.error(error);
          });
      } catch (err) {
        console.log(err);
      }
    },
    validateCPF(cpf) {
      const cpfNumbers = this.removeNonNumeric(cpf);
      if (cpfNumbers.length !== 11) {
        return false;
      }

      const cpfArray = cpfNumbers.split("").map(Number);
      const [digit1, digit2] = cpfArray.slice(9);

      const sum = (arr) => arr.reduce((acc, curr) => acc + curr, 0);
      const mod = (n, mod) => (n % mod < 2 ? 0 : mod - (n % mod));

      const checkDigit = (arr, factor) =>
        mod(sum(arr.map((num, idx) => num * (factor - idx))), 11);

      if (checkDigit(cpfArray.slice(0, 9), 10) !== digit1) {
        return false;
      }

      if (checkDigit(cpfArray.slice(0, 10), 11) !== digit2) {
        return false;
      }

      return true;
    },

    removeNonNumeric(value) {
      return value.replace(/\D/g, "");
    },
    close() {
      this.dialog = false;
    },
    // addTitular() {
    //   const config = {
    //     headers: { Authorization: `Bearer ${this.token}` },
    //   };
    //   const cpf = this.removeNonNumeric(this.titularUser.cnpj_cpf);
    //   if (!this.validateCPF(cpf)) {
    //     this.$toast.error("Digite um CPF Válido");
    //     return;
    //   }

    //   const data = {
    //     CPF: cpf,
    //   };
    //   axios
    //     .post(this.urlEnv + "updateInvestidor", data, config)
    //     .then(() => {
    //       this.$toast.success("Iniciando investimento")
    //       this.$router.push({
    //         name: "templateRegister",
    //       });
    //     })
    //     .catch((error) => {
    //       if (error.response) {
    //         // console.log(error.response.data.mensagem)
    //         const errorCPF = error.response.data.mensagem;
    //         this.$toast.error(errorCPF);
    //       } else {
    //         console.error(error);
    //       }
    //     });
    // },
  },

  mounted() {
    console.log(this.currentScreenIndex)
    // this.getInvestidor();
  },
};
</script>


<style scoped>
.timeline-container {
  display: block;
  /* Exibir os itens do timeline em uma única coluna */
}

.text-regular {
  font-size: 15px;
  font-weight: 400;

}

.custom-container {
  /* max-width: 1440px; */
  margin: 0 auto;
  padding: 11px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.text-h4,
.text-xl-h4,
.text-xl-h5,
.text-h3,
.text-h5 .text-h6,
.white--text,
.text-center,
.secondary--text,
.d-flex {
  font-family: 'Raleway', cursive !important;
}

.gifLogo {
  position: absolute;
  top: -30%;
  left: 27%;
  bottom: 2%;
  max-width: 40%;
}

.custom-background {
  background-image: url('@/assets/imageInicial2.png');
  background-size: cover;
  /* background-position-x: 140%; */
}

@media (max-width: 600px) {
  .custom-background {
    background-image: none;
  }
}
</style>