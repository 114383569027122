import { render, staticRenderFns } from "./ForgotPasswordMobile.vue?vue&type=template&id=210d8dd2&scoped=true"
import script from "./ForgotPasswordMobile.vue?vue&type=script&lang=js"
export * from "./ForgotPasswordMobile.vue?vue&type=script&lang=js"
import style0 from "./ForgotPasswordMobile.vue?vue&type=style&index=0&id=210d8dd2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "210d8dd2",
  null
  
)

export default component.exports