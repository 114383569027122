<template>
  <div>
    <layout style="z-index: 100; position: relative;display: flex; flex-direction: column; min-height: 100vh;">
      <template v-slot:content>
        <div style="color: #032a5a;" class="px-6 pt-6">
          <h1 class="mb-5 font-weight-bold " style="font-size: 20px;">Fale conosco!</h1>
        </div>
        <div class="mt-lg-4 mx-4 mt-lg-8 px-lg-16 overflow-y-auto" style="height: 100vh;">
          <v-expansion-panels flat accordion>
            <v-expansion-panel style="border-bottom: 2px solid #E6E6E6;">
              <v-expansion-panel-header class="font-weight-bold primary--text">PERGUNTAS FREQUENTES</v-expansion-panel-header>
              <v-expansion-panel-content class="primary--text">
                <v-expansion-panels flat accordion>
                            <v-expansion-panel class="my-1"
                                v-for="(faq, index) in faqs" :key="index">
                                <v-expansion-panel-header style="background-color: #E6E6E6;"
                                    class="elevation-0 font-weight-bold">{{ faq.question }}</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    {{ faq.answer }}
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel class="ma-1 pa-1" style="border-bottom: 2px solid #E6E6E6;">
              <v-expansion-panel-header class="font-weight-bold primary--text">CONTATO</v-expansion-panel-header>
              <v-expansion-panel-content class="primary--text">
                Caso não tenha encontrado a resposta para sua dúvida no campo 'Perguntas frequentes' aqui ao lado, por favor deixe sua mensagem que lhe responderemos por e-mail.
                <p class="primary--text mt-3 font-weight-bold">Título da sua mensagem</p>
                <v-textarea v-model="texto" outlined label="Escreva sua mensagem aqui!"></v-textarea>
                <div style="display:flex; justify-content:center">
                  <v-btn @click="sendEmailContact" color="secondary" class="rounded-xl px-16 mx-16">Enviar</v-btn>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </template>
    </layout>
  </div>
</template>

<script>
import layout from "@/layouts/internalLayoutMobile.vue";
import axios from "axios";

export default {
  metaInfo() {
    return {
      title: 'BELat - Home'
    }
  },
  components: {
    layout,
  },
  data() {
    return {
      faqs: [],
      tabs: null,
      tabNames: ['Dados pessoais', 'Informações adicionais', 'Declarações'],
      text: '',
      userId: localStorage.getItem("userId"),
      user: {
        name: '',
        email: '',
        phone_number: '',
      },
      texto: '',
      token: localStorage.getItem("token"),
      urlEnv: process.env.VUE_APP_SERVICE_URL,
      right: null,
    };
  },
  methods: {
    async loadFAQs() {
            try {
                const currentLocale = this.$i18n.locale;
                const response = await axios.get(`/locales/${currentLocale}_faqs.json`);
                this.faqs = response.data;
            } catch (error) {
                console.error("Error loading FAQs:", error);
            }
        },
    removeNonNumeric(value) {
      return value?.replace(/\D/g, "");
    },
    async sendEmailContact() {
      // Carregar dados do usuário antes de enviar o e-mail
      await this.getUserData();

      const userContact = {
        nome: this.user.name,
        telefone: this.removeNonNumeric(this.user.phone_number),
        email: this.user.email,
        texto: this.texto,
      };

      console.log(userContact);

      try {
        const response = await axios.post(this.urlEnv + "sendContactEmail", userContact, {
          headers: {
            'Authorization': `Bearer ${this.token}`,
            'X-Frontend-URL': window.location.origin
          }
        });

        if (response.data.status) {
          this.$toast.success('Mensagem enviada com sucesso!');
        } else {
          this.$toast.error('Erro ao enviar a mensagem:');
        }
      } catch (error) {
        this.$toast.error('Erro ao enviar a mensagem:', error);
        this.$toast.error('Erro ao enviar a mensagem. Tente novamente mais tarde.');
      }
    },
    async getUserData() {
      const config = {
        headers: { Authorization: `Bearer ${this.token}` },
      };

      try {
        const response = await axios.get(this.urlEnv + "user", config);
        let userData = response.data;
        this.user.name = userData.name;
        this.user.email = userData.email;
        this.user.phone_number = userData.phone_number;
        console.log(userData);
      } catch (error) {
        console.log('Erro ao obter dados do usuário:', error);
      }
    },
  },
  async mounted() {
    await this.getUserData();
    this.loadFAQs();
  },
};
</script>

<style scoped>
.v-navigation-drawer__border {
  display: none;
}
.rounded-card {
  border-radius: 20px;
  border: 1px solid #032a5a
}
.rounded-card-2 {
  border-radius: 20px;
  border: 1px solid #DF6E8B
}
.rounded-card-3 {
  border-radius: 20px;
  border: 1px solid #14aa95;
}
.opacityButton {
  background: #14aa95;
}
.opacityButton2 {
  background: #DF6E8B;
}
.oi {
  border-bottom: 10px solid black;
}
.text-h4,
.text-xl-h4,
.text-xl-h5,
.text-h3,
.text-h5 .text-h6,
.white--text,
.text-center,
.d-flex {
    font-family: 'Raleway', cursive !important;
}
@media (min-width: 2560px) {
  .textHome {
    font-size: 24px; 
  }
  .textHome2 {
    font-size: 21px; 
  }
}
@media (min-width: 1920px) and (max-width: 2559px) {
  .textHome {
    font-size: 17px !important; 
  }
  .textHome2 {
    font-size: 21px; 
  }
}
@media (min-width: 1280px) and (max-width: 1919px) {
  .textHome {
    font-size: 14px; 
  }
  .textHome2 {
    font-size: 21px; 
  }
}
@media (max-width: 1279px) {
  .textHome {
    font-size: 12px; 
  }
  .textHome2 {
    font-size: 16px; 
  }
}
</style>
