import layout from "../../layouts/internalLayout.vue";
import HomePage from "./HomePage.vue";
import HomePageInvest from "./HomePageInvest.vue"
import HomePageOportunity from "./HomePageOportunity.vue"
import axios from "axios";


async function getInvestorStatus(token, urlEnv) {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  try {
    const response = await axios.get(urlEnv + "getInvestidor", config);
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

async function changeHomeComponent() {
  const token = localStorage.getItem("token");
  const urlEnv = process.env.VUE_APP_SERVICE_URL;

  if (!token || !urlEnv) {
    console.error("Missing token, or service URL.");
    return HomePage;
  }

  const investorStatus = await getInvestorStatus(token, urlEnv);
  if (!investorStatus) {
    console.error("Failed to retrieve investor status.");
    return HomePage;
  }

  if (investorStatus.data.investiu) {
    return HomePageInvest;
  }

  if (investorStatus.data.investidor.questions!=null)
    if( investorStatus.data.investidor.questions['finished']) {
    return HomePageOportunity;
  }

  return HomePage;
}

const routes = {
  component: layout,
  children: [
    {
      name: "HomePage",
      path: "home",
      component: changeHomeComponent,
      beforeEnter: (to, from, next) => {
        const isMobile = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        
        if (isMobile) {
          next(`/mobile/HomeProfile`);
        } else {
          next();
        }
      },
      meta: {
        requiresAuth: true,
      },
    }
  ],
};

export default routes;
