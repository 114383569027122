<template>
  <layout>
    <template v-slot:content>
      <div class="d-flex flex-column items-center primary">
        <div class="px-6 py-3 d-flex align-center" style="border-bottom: 1px solid #14aa95;">
          <v-row no-gutters class="d-flex">
            <v-col cols="3" class="justify-center">
              <v-list-item-avatar class="ml-2" size="60">
                <v-img :src="user.profile_photo_url"></v-img>
              </v-list-item-avatar>
            </v-col>
            <v-col cols="9" class="py-2 justify-center">
              <div class="text-h5 font-weight-medium text-center white--text" style="display: flex; align-items: center; font-family: 'Raleway'"> 
                {{ user.name }}
              </div>
              <div class="text-center white--text" style="display: flex; align-items: center; font-family: 'Raleway'"> 
                {{ user.email }}
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="pa-4">
        <v-list>
          <v-list-item v-for="(item, index) in listItems" :key="index" @click="navigateTo(item.route)">
            <v-list-item-icon >
              <v-img :src="getIconPath(item.icon)" class="custom-icon"></v-img>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-caption custom-list-item-title">{{ item.title }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon class="custom-icon">mdi-chevron-right</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </div>
    </template>
  </layout>
</template>

<script>
import layout from "../../layouts/internalLayoutMobile.vue";
import axios from "axios";

export default {
  metaInfo() {
    return {
      title: 'BELat - Minhas Informações'
    }
  },
  components: {
    layout,
  },
  data() {
    return {
      listItems: [
        { title: 'Dados pessoais', icon: 'user_icon.png', route: 'dados-pessoais' },
        { title: 'Informações adicionais', icon: 'inform_icon.png', route: 'informacoes-adicionais' },
        { title: 'Endereço', icon: 'house_icon.png', route: 'endereco' },
        { title: 'Dados bancários', icon: 'bank_icon.png', route: 'dados-bancarios' },
        { title: 'Meus documentos', icon: 'doc_icon.png', route: 'meus-documentos' },
      ],
      user: {
        name: null,
        email: null,
        phone_number: null,
        profile_photo_url: null,
      },
      token: localStorage.getItem("token"),
      urlEnv: process.env.VUE_APP_SERVICE_URL,
    };
  },
  methods: {
    getIconPath(icon) {
      return require(`@/assets/icon/${icon}`);
    },
    navigateTo(section) {
      this.$router.push({ name: 'InfoPage', params: { section } });
    },
    async getUserData() {
      const config = {
        headers: { Authorization: `Bearer ${this.token}` },
      };
      axios.get(this.urlEnv + "user", config).then(response => {
        let userData = response.data;
        this.user.name = userData.name;
        this.user.email = userData.email;
        this.user.phone_number = userData.phone_number;
        this.user.profile_photo_url = userData.profile_photo_url;
      }).catch (error => {
        console.error(error);
      });
    },
  },
  mounted() {
    this.getUserData();
  },
};
</script>

<style scoped>
.custom-list-item-title {
  font-size: 16px !important;
  color: #032A5A;
  font-weight: 700;
}

.custom-icon {
  max-width: 20px;
  color: #032A5A;
  font-size: 24px;
}
</style>
