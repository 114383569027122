import MyInfo from "./MyInfo.vue";
import MobileMyInfo from "./MobileMyInfo.vue";
import InfoPage from "./Pages/InfoPage.vue"; // Nova página de destino que renderiza com base no parâmetro

const routes = {
  children: [
    {
      name: "MyInfo",
      path: "user/MyInfo",
      component: MyInfo,
      meta: {
        requiresAuth: true,
      },
    },
    {
      name: "MobileMyInfo",
      path: "mobile/MyInfo",
      component: MobileMyInfo,
      meta: {
        requiresAuth: true,
      },
    },
    {
      name: "InfoPage",
      path: "info/:section",
      component: InfoPage,
      props: true,
      meta: {
        requiresAuth: true,
      },
    },
  ],
};

export default routes;
