import SignIn from './SignIn.vue';
import SignUp from './SignUp.vue';
import MobileHome from './MobileHome.vue';
import MobileJuridica from "./MobileJuridica.vue"
import MobileLogin from './MobileLogin.vue';
import MobileSignUp from './MobileSignUp.vue';
import PessoaJuridica from "./PersonJuridica.vue";
import ForgotPassword from "./ForgotPassword.vue";
import ForgotPasswordMobile from "./ForgotPasswordMobile.vue";
import SendEmail from "./SendEmail.vue";
import SendEmailMobile from "./SendEmailMobile.vue";
import ResetPassword from "./ResetPassword.vue";
import ChangePassword from "./ChangePassword.vue";
import RegisterComplete from "./RegisterComplete";
import SplashScreen from "./SplashScreen";
import TemplateRegisterMobile from "./TemplateRegisterMobile"
import sendEmailPassword from "./sendEmailPassword"
import MobileProfile from "./MobileProfile"
import ResetPasswordMobile from "./ResetPasswordMobile"
import MobileBusiness from "./MobileBusiness"
import MobileContact from "./MobileContact"
import MobileFIDC from "./MobileFIDC"
import FIDC from "./FIDC"
import ViewBusinessMobile from "./ViewBusinessMobile"
import ConfigMobile from "./ConfigPageMobile"
import ChangePasswordMobile from "./NewPassword.vue"

function isTablet() {
  const userAgent = navigator.userAgent.toLowerCase();
  return /ipad|android(?!.*mobi)/.test(userAgent);
}

// Função para forçar a orientação de tela
function tryForceLandscapeOrientation() {
  if (screen.orientation && screen.orientation.lock) {
    return screen.orientation.lock('landscape');
  } else if (screen.lockOrientation) {
    return screen.lockOrientation('landscape');
  } else if (screen.mozLockOrientation) {
    return screen.mozLockOrientation('landscape');
  } else if (screen.msLockOrientation) {
    return screen.msLockOrientation('landscape');
  }
  console.warn('Orientation lock not supported on this browser.');
  return Promise.reject('Orientation lock not supported');
}

// Exibir uma mensagem para que o usuário mude a orientação manualmente
function showOrientationWarning() {
  alert('Para uma melhor experiência mude a orientação do seu dispositivo para horizontal.');
}


const routes = {
  children: [
    {
      name: 'Login',
      path: '',
      component: SignIn,
      beforeEnter: (to, from, next) => {
        // Verificar se é um dispositivo móvel
        const isMobile = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        
        // Se for móvel, redirecionar para a rota de home móvel
        if (isTablet()) {
          if (screen.orientation && screen.orientation.type.startsWith('landscape')) {
          next();
        } else {
          tryForceLandscapeOrientation()
            .then(() => {
              next();
            })
            .catch(() => {
              showOrientationWarning();
              next(); // TODO talvez devessemos não mostrar a tela
            });
        }} else if (isMobile) {
          next('/mobile/home');
        } else {
          next();
        }
      },
    },
    {
      name: 'Cadastrar',
      path: 'signup', 
      component: SignUp,
      beforeEnter: (to, from, next) => {
        const isMobile = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        
        if (isMobile) {
          next(`/mobile/signUp`);
        } else {
          next();
        }
      },
    },
    {
      name: 'PessoaJuridica',
      path: '/mobile/juridica',
      component: MobileJuridica,
    },
    {
      name: 'MobileHome',
      path: '/mobile/home',
      component: MobileHome,
    },
    {
      name: 'MobileProfile',
      path: '/mobile/HomeProfile',
      component: MobileProfile
    },
    {
      name: 'TemplateRegisterMobile',
      path: '/mobile/TemplateRegisterMobile',
      component: TemplateRegisterMobile,
    },
    {
      name: 'MobileBusiness',
      path: '/mobile/MobileBusiness',
      component: MobileBusiness,
    },
    {
      name: 'MobileContact',
      path: '/mobile/MobileContact',
      component: MobileContact
    },
    {
      name: 'MobileFIDC',
      path: '/mobile/fidc',
      component: MobileFIDC
    },
    {
      name: 'SplashScreen',
      path: '/mobile/RegisterHome',
      component: SplashScreen,
    },
    {
      name: 'MobileLogin',
      path: '/mobile/login',
      component: MobileLogin,
    },
    {
      name: 'ConfigMobile',
      path: '/mobile/config',
      component: ConfigMobile,
    },
    {
      name: 'MobileSignUp',
      path: '/mobile/signUp',
      component: MobileSignUp,
    },
    {
      name: "ViewBusinessMobile",
      path: "mobile/business/view/:businessId",
      component: ViewBusinessMobile,
    },
    {
      name: 'sendEmailPassword',
      path: '/mobile/sendEmailPassword',
      component: sendEmailPassword,
    },
    {
      name: 'FIDC',
      path: 'FIDC', 
      component: FIDC,
      beforeEnter: (to, from, next) => {
        const isMobile = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        
        if (isMobile) {
          next(`/mobile/fidc`);
        } else {
          next();
        }
      },
    },
    {
      name: 'Juridica',
      path: 'juridica', 
      component: PessoaJuridica,
      beforeEnter: (to, from, next) => {
        const isMobile = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        
        if (isMobile) {
          next(`/mobile/juridica`);
        } else {
          next();
        }
      },
    },
    {
      name: 'ForgotPassword',
      path: 'forgotPassword',
      component: ForgotPassword,
      beforeEnter: (to, from, next) => {
        const isMobile = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        
        if (isMobile) {
          next(`/mobile/forgotPasswordMobile`);
        } else {
          next();
        }
      },
    },
    {
      name: 'ForgotPasswordMobile',
      path: '/mobile/forgotPasswordMobile',
      component: ForgotPasswordMobile,
    },
    {
      name: 'SendEmail',
      path: 'sendEmail',
      component: SendEmail,
      beforeEnter: (to, from, next) => {
        const isMobile = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        
        if (isMobile) {
          next(`/mobile/sendEmailMobile`);
        } else {
          next();
        }
      },
    },
    {
      name: 'SendEmailMobile',
      path: 'sendEmailMobile',
      component: SendEmailMobile,
    },
    {
      name: 'ResetPasswordMobile',
      path: 'mobile/reset-password/:token/:email',
      component: ResetPasswordMobile
    },
    {
      name: 'ResetPassword',
      path: '/reset-password/:token/:email',
      component: ResetPassword,
      beforeEnter: (to, from, next) => {
        const isMobile = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        
        if (isMobile) {
          const { token, email } = to.params;
          next(`/mobile/reset-password/${token}/${email}`);
        } else {
          next();
        }
      },
      
    },
    {
      name: 'ChangePassword',
      path: 'config/change-password',
      component: ChangePassword,
    },
    {
      name: 'ChangePasswordMobile',
      path: 'mobile/config/change-password',
      component: ChangePasswordMobile,
    },
    {
      name: 'RegisterComplete',
      path: 'registerComplete/:id/:hash',
      component: RegisterComplete,
    }
  ]
};

export default routes;
