import { render, staticRenderFns } from "./ResetPasswordMobile.vue?vue&type=template&id=38121566&scoped=true"
import script from "./ResetPasswordMobile.vue?vue&type=script&lang=js"
export * from "./ResetPasswordMobile.vue?vue&type=script&lang=js"
import style0 from "./ResetPasswordMobile.vue?vue&type=style&index=0&id=38121566&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38121566",
  null
  
)

export default component.exports