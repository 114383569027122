import layout from "../../layouts/internalLayout.vue";
import ListView from "./ListView.vue";
import ViewBusiness from "./ViewBusiness.vue";

const routes = {
  component: layout,
  children: [
    {
      name: "ListView",
      path: "business",
      component: ListView,
      meta: {
        requiresAuth: true,
      },
    },
    {
      name: "ViewBusiness",
      path: "business/view/:businessId",
      component: ViewBusiness,
      meta: {
        requiresAuth: true,
      },
    },
  ],
};

export default routes;
