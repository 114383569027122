// import axios from 'axios';

export function getPhoto(photos) {
  if (!photos || photos.length === 0 || (photos[0] && !photos[0].content)) {
    return require("../assets/imagem-n-disp.png");
  } else {
    if(Array.isArray(photos)) return photos[0].content;
    else return photos.content ?? require("../assets/imagem-n-disp.png");
  }
}

export function numberToReal(numero) {
  if (numero === null || isNaN(numero)) return "Valor não informado";
  numero = numero.toFixed(2).split(".");
  numero[0] = "R$ " + numero[0].split(/(?=(?:...)*$)/).join(".");
  return numero.join(",");
}

export function numberToPercentage(numero) {
  if (numero === null || isNaN(numero)) return "NA";
  const porcentagem = `${(numero).toFixed(2).replace('.', ',')}%`;
  return porcentagem;
}

export function calcDias(date) {
  var today = new Date();
  var date_to_reply = new Date(date);
  var timeinmilisec = date_to_reply.getTime() - today.getTime();
  if (timeinmilisec < 0) return 0;
  return Math.ceil(timeinmilisec / (1000 * 60 * 60 * 24));
}

export function redirectTo(router, routeName) {
  router.push({
    name: routeName,
    query: { renderOnTabFour: true }
  });
}

// export function getInvestidor(token, urlEnv, userId) {
//   const config = {
//     headers: { Authorization: `Bearer ${token}` },
//   };
//   axios
//     .get(`${urlEnv}getInvestidor/${userId}`, config)
//     .then((response) => {
//       console.log(response);
//     })
//     .catch((error) => {
//       console.error(error);
//     });
// }
