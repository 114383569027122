<template>
  <div>
    <v-dialog max-width="500" v-model="dialog">
      <ModalCPF @close="close" />
    </v-dialog>
    <layout>
      <template v-slot:content>
        <v-container class="custom-container mt-16" fluid>
          <v-row class="d-flex justify-center">
            <v-col cols="12" md="6">
              <v-card class="pa-8">
                <v-card-title class="d-flex justify-center">
                  <h2 class="font-weight-regular">Seu login foi criado</h2>
                </v-card-title>
                <v-card-text class="text-center">
                  <p class="font-weight-regular">
                    Para começar a investir, precisamos que você preencha alguns
                    dados:
                  </p>
                </v-card-text>
                <v-card-text class="text-center">
                  <v-text-field
                    v-mask="'###.###.###-##'"
                    outlined
                    label="CPF"
                    v-on:keyup.enter="addTitular"
                    append-icon="mdi-help-circle-outline"
                    @click:append="dialog = true"
                    v-model="titularUser.cnpj_cpf"
                    :disabled="
                      titularUser.cnpj_cpf !== undefined &&
                      titularUser.cnpj_cpf == 15
                    "
                  ></v-text-field>
                </v-card-text>
                <v-hover v-slot="{ hover }">
                  <v-card-actions class="justify-center">
                    <v-btn
                      :class="{ 'on-hover': hover }"
                      class="pa-6 px-16 transition-fast-in-fast-out"
                      :color="hover ? 'primary' : 'secondary'"
                      @click="addTitular"
                    >
                      Confirmar
                    </v-btn>
                  </v-card-actions>
                </v-hover>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </layout>
    <!-- <v-snackbar v-model="snackbar" color="error" multi-line>
      {{ snackbarText }}
    </v-snackbar> -->
  </div>
</template>

<script>
import ModalCPF from "./Modal/ModalCPF.vue";
import layout from "../../layouts/internalLayout.vue";
import axios from "axios";
export default {
  components: {
    layout,
    ModalCPF,
  },
  data() {
    return {
      userId: localStorage.getItem("userId"),
      cnpj_cpf: null,
      dialog: true,
      token: localStorage.getItem("token"),
      urlEnv: process.env.VUE_APP_SERVICE_URL,
      titularUser: {},
      items: [
        {
          title: "Meus Investimentos",
          icon: "mdi-home-city",
          route: "/user/MyInvestiments",
        },
        { title: "Meus dados", icon: "mdi-account", route: "/user/MyInfo" },
      ],
    };
  },
  methods: {
    async getInvestidor() {
      const config = {
        headers: { Authorization: `Bearer ${this.token}` },
      };
      try {
        axios
          .get(this.urlEnv + `getInvestidor`, config)
          .then((response) => {
            this.titularUser = response.data.data.investidor;
            console.log(this.titularUser);
          })
          .catch((error) => {
            console.error(error);
          });
      } catch (err) {
        console.log(err);
      }
    },
    validateCPF(cpf) {
      const cpfNumbers = this.removeNonNumeric(cpf);
      if (cpfNumbers.length !== 11) {
        return false;
      }

      const cpfArray = cpfNumbers.split("").map(Number);
      const [digit1, digit2] = cpfArray.slice(9);

      const sum = (arr) => arr.reduce((acc, curr) => acc + curr, 0);
      const mod = (n, mod) => (n % mod < 2 ? 0 : mod - (n % mod));

      const checkDigit = (arr, factor) =>
        mod(sum(arr.map((num, idx) => num * (factor - idx))), 11);

      if (checkDigit(cpfArray.slice(0, 9), 10) !== digit1) {
        return false;
      }

      if (checkDigit(cpfArray.slice(0, 10), 11) !== digit2) {
        return false;
      }

      return true;
    },

    removeNonNumeric(value) {
      return value.replace(/\D/g, "");
    },
    close() {
      this.dialog = false;
    },
    addTitular() {
      const config = {
        headers: { Authorization: `Bearer ${this.token}` },
      };
      const cpf = this.removeNonNumeric(this.titularUser.cnpj_cpf);
      if (!this.validateCPF(cpf)) {
        this.$toast.error("Digite um CPF Válido");
        return;
      }

      const data = {
        CPF: cpf,
      };
      axios
        .post(this.urlEnv + "updateInvestidor", data, config)
        .then(() => {
          this.$toast.success("Iniciando investimento")
          this.$router.push({
            name: "templateRegister",
          });
        })
        .catch((error) => {
          if (error.response) {
            // console.log(error.response.data.mensagem)
            const errorCPF = error.response.data.mensagem;
            this.$toast.error(errorCPF);
          } else {
            console.error(error);
          }
        });
    },
  },

  mounted() {
    this.getInvestidor();
  },
};
</script>
