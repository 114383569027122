import ContactUsPage from "./ContactUsPage.vue"

const routes = {
  children: [
    {
      name: 'ContactUs',
      path: 'contact-us', 
      component: ContactUsPage,
      meta: {
        requiresAuth: true,
      },
    },
  ]
};

export default routes;
