<template>
  <v-main style="background: rgb(249 250 251); height: 100vh; padding: 0;">
    <v-container class="custom-container mt-8" style="border: 0px" :style="{
      display: currentScreenIndex === 0 ? 'grid' : 'block',
      justifyItems: currentScreenIndex === 0 ? 'center' : 'initial',
      alignItems: currentScreenIndex === 0 ? 'center' : 'initial'
    }" fluid>
      <!-- <v-row>
                  <v-col cols="12" md="12">
                    <v-img max-width="300" src="../../../assets/belat.png"></v-img>
                  </v-col>
                </v-row> -->
      <v-app-bar fixed color="white" style="z-index: 99;">
        <v-img max-width="150" src="../../../assets/belat.png"></v-img>
        <v-spacer></v-spacer>

      </v-app-bar>

      <v-app-bar fixed color="#D64D7A"
        style="height: 10px; margin-top: 0px; transform: translateY(0px); top: auto !important; left: 0px; right: 0px; z-index: 99; bottom: 0px;">
      </v-app-bar>
      <div class="d-flex flex-column items-center primary" style="width: 100%">
        <div class="px-6 py-10 d-flex align-center" style="border-bottom: 1px solid #14aa95; justify-content: center">
          <div class="d-flex">
            <div class="text-h5 font-weight-medium text-center white--text"
              style="display: flex; align-items: center; font-family: 'Raleway'">
              {{ $t('investment_profile') }}
            </div>
          </div>
        </div>
      </div>

      <v-row class="d-flex justify-center">
        <div class="">
          <QuestionTimeLine :items="currentTimeline" :activeIndex="currentPosition"
            :completedIndexes="completedIndexes" />
        </div>

      </v-row>

      <template>
        <div class="mt-5" style="display: flex; justify-content: center;">
          <v-card elevation="5" :width="$vuetify.breakpoint.lgAndUp ? ($vuetify.breakpoint.xl ? '850' : '800') : ''"
            :height="$vuetify.breakpoint.lgAndUp ? ($vuetify.breakpoint.xl ? '380' : '350') : ''" class="pa-10 mt-3"
            style="display: grid; justify-content: center; align-items: center;" rounded>
            <span class="px-lg-16 text-h5 font-weight-medium primary--text text-center">Seu perfil de investidor é:
              <br><br><strong>{{ selectedProfile.name }}</strong></span>
            <span
              class="px-lg-16 text-lg-body-1 font-weight-medium primary--text text-center my-10">{{ selectedProfile.desc }}</span>
          </v-card>
        </div>
      </template>

      <v-col cols="12" class="mt-4" style="padding: 2px">
        <div class="d-flex justify-center">
          <v-btn class="mr-10 px-16 primary--text" style="border: 1px solid #00265b" v-if="currentQuestionIndex == 0"
            rounded @click="backPage">{{ $t('back') }}</v-btn>
          <v-btn class="px-12" dark rounded color="primary" @click="handleButtonClick">
            {{ $t('finish') }}
          </v-btn>
        </div>
      </v-col>

    </v-container>
  </v-main>
</template>

<script>
import QuestionTimeLine from "../Components/QuestionTimeLine.vue"
import axios from 'axios';
export default {
  props: {
    currentTimeline: Array,
    currentPosition: Number,
  },
  components: {
    QuestionTimeLine
  },
  data() {
    return {
      pass: false,
      activeIndex: 0,
      completedIndexes: [0],
      currentQuestionIndex: 0,
      token: localStorage.getItem("token"),
      urlEnv: process.env.VUE_APP_SERVICE_URL,
      profiles: [
        {
          name: 'Conservador',
          desc: 'Clientes com este perfil têm como objetivo a preservação do capital e possuem baixa tolerância a riscos. Também é representado por clientes que, apesar de estarem dispostos a correr um pouco mais de riscos na busca por retornos diferenciados, tenham necessidade de sacar os recursos em curto período de tempo. '
        },
        {
          name: 'Moderado',
          desc: 'Clientes com este perfil estão dispostos a correr alguns riscos em investimentos, buscando um retorno diferenciado no médio prazo, com baixa necessidade de liquidez no curto prazo, havendo disponibilidade para diversificar parte das aplicações em alternativas mais arrojadas. '
        },
        {
          name: 'Arrojado',
          desc: 'Este perfil é representado por clientes com alta tolerância a riscos, baixa ou nenhuma necessidade de liquidez no curto/médio prazo e que estejam dispostos a aceitar as oscilações dos mercados de risco (e possíveis perdas) na busca por retornos diferenciados no longo prazo. '
        }
      ],
      selectedProfile: {
        name: 'Carregando...',
        desc: ''
      },
      currentScreenIndex: 0,
    }
  },
  mounted() {
    this.getMeusDados()
  },
  methods: {
    backPage() {
      this.$parent.goToPreviousScreen();
    },
    async getMeusDados() {
      const config = {
        headers: { Authorization: `Bearer ${this.token}` },
      };
      try {
        axios
          .get(this.urlEnv + `getQuestions`, config)
          .then((response) => {
            let totalPoints = 0;
            const questions = response.data.data.questions;
            for (var key in questions) {
              if (Object.prototype.hasOwnProperty.call(questions, key)) {
                if (Object.prototype.hasOwnProperty.call(questions[key], 'points')) {
                  totalPoints += questions[key].points;
                }
              }
            }
            if (totalPoints <= 22)
              this.selectedProfile = this.profiles.find(profile => profile.name == "Conservador");
            else if (totalPoints >= 23 && totalPoints <= 32)
              this.selectedProfile = this.profiles.find(profile => profile.name == "Moderado");
            else if (totalPoints >= 33)
              this.selectedProfile = this.profiles.find(profile => profile.name == "Arrojado");
          })
          .catch((error) => {
            console.error(error);
          });
      } catch (err) {
        console.log(err);
      }
    },
    handleButtonClick() {
      this.finishStage();
    },
    finishStage() {
      this.pass = true
      const config = {
        headers: { Authorization: `Bearer ${this.token}` },
      };
      const data = {
        profile: this.selectedProfile,
        investComplete: true,
      };
      try {
        axios
          .post(this.urlEnv + "saveQuestions", data, config)
          .then(() => {
            this.$emit('incrementPosition')
            this.$parent.goToNextScreen();
          })
          .catch((error) => {
            this.pass = false
            this.$toast.error(error);
          });
      } catch (error) {
        console.log(error);
      }
    },
    getColor(position) {
      return position <= this.currentPosition ? 'secondary' : 'gray'; // Retorna 'secondary' se a posição for menor ou igual a currentScreenIndex
    },
  },

}
</script>

<style scoped>
.text-h4,
.text-xl-h4,
.text-xl-h5,
.text-h3,
.text-h5 .text-h6,
.white--text,
.primary--text,
.text-center,
.d-flex {
  font-family: 'Raleway', cursive !important;
}

.custom-container {
  /* max-width: 1440px; */
  margin: 0 auto;
  border: 1px solid #fff;
  padding: 20px 0 ;
}

.stage-indicator {
  display: inline-block;
  width: 100px;
  /* Ajuste conforme necessário */
  padding: 5px;
  margin: 0 10px;
  /* Espaçamento entre as etapas */
  border: 1px solid #ccc;
  border-radius: 5px;
}

.v-timeline::before {
  transform: rotate(90deg) !important;
}

.v-application--is-ltr .v-timeline--dense:not(.v-timeline--reverse)::before {
  left: 0 !important
}
</style>