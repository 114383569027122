<template>
  <div class="d-flex flex-column align-center">
    <v-row style="width: 80%">
      <v-col class="pt-lg-10 pt-5 " cols="12">
        <div style="display: flex; justify-content: space-between;" class=" py-3">
          <span class="primary--text text-md-h6">Documento de identificação frente</span>
          <div class="d-flex align-center">
            <input type="file" ref="identificationFileInput" style="display: none" @change="onFileChange('identification', $event)">
            <span class="mr-2" v-if="identificationFileName">{{ verifyNameFile(identificationFileName) }}</span>
            <svg @click="openFileInput('identification')" style="width: 1.5rem; cursor: pointer" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" class="w-4 h-4">
              <path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
            </svg>
          </div>
        </div>
        <v-divider color="#14AA95" class="mt-lg-4"></v-divider>
        <div style="display: flex; justify-content: space-between;" class="mt-2 py-3">
          <span class="primary--text text-md-h6">Documento de identificação verso</span>
          <div class="d-flex align-center">
            <input type="file" ref="identificationBackFileInput" style="display: none" @change="onFileChange('identificationBack', $event)">
            <span class="mr-2" v-if="identificationBackFileName">{{ verifyNameFile(identificationBackFileName) }}</span>
            <svg @click="openFileInput('identificationBack')" style="width: 1.5rem; cursor: pointer" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" class="w-4 h-4">
              <path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
            </svg>
          </div>
        </div>
        <v-divider color="#14AA95" class="mt-lg-4 "></v-divider>
        <div style="display: flex; justify-content: space-between;" class="mt-2 py-3">
          <span class="primary--text text-md-h6">Comprovante de residência</span>
          <div class="d-flex align-center">
            <input type="file" ref="residenceFileInput" style="display: none" @change="onFileChange('residence', $event)">
            <span class="mr-2" v-if="residenceFileName">{{ verifyNameFile(residenceFileName) }}</span>
            <svg @click="openFileInput('residence')" style="width: 1.5rem; cursor: pointer" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" class="w-4 h-4">
              <path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
            </svg>
          </div>
        </div>
        <v-divider color="#14AA95" class="mt-lg-4"></v-divider>
      </v-col>
    </v-row>
    <v-col cols="12" class="d-flex justify-center">
      <v-btn
        class="transition-fast-in-fast-out mr-2"
        color="primary"
        @click="uploadFile"
        rounded
        :disabled="!identificationFileName && !residenceFileName && !identificationBackFileName"
      >
        Confirmar envio
      </v-btn>
    </v-col>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      userId: localStorage.getItem("userId"),
      token: localStorage.getItem("token"),
      urlEnv: process.env.VUE_APP_SERVICE_URL,
      identificationFileName: null,
      residenceFileName: null,
      identificationBackFileName: null,
    };
  },
  methods: {
    uploadFile() {
      const formData = new FormData();
      formData.set('rg', this.$refs['identificationFileInput'].files[0]);
      formData.set('rg_tras', this.$refs['identificationBackFileInput'].files[0]);
      formData.set('comprovante', this.$refs['residenceFileInput'].files[0]);

      axios.post(`${this.urlEnv}uploadDocs`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${this.token}`,
        },
      })
      .then((response) => {
        this.identificationFileName = null;
        this.residenceFileName = null;
        this.identificationBackFileName = null;
        if(response.data.code == 'WARNING')
          this.$toast.warning(response.data.mensagem);
        else
          this.$toast.success(response.data.mensagem);
          
      })
      .catch((error) => {
        this.$toast.error(error.data.mensagem);
        console.log(error);
      });
    },
    onFileChange(type, event) {
      const file = event.target.files[0];
      if (file.type.startsWith('image/') || file.type === 'application/pdf') {
        if (file.type === 'application/pdf') {
          this.$refs[`${type}FileInput`].files = event.target.files;
        } else {
          const reader = new FileReader();

          reader.onload = (e) => {
            const img = new Image();
            img.src = e.target.result;

            img.onload = () => {
              const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d');

              const maxWidth = 800;
              const maxHeight = 600;
              let width = img.width;
              let height = img.height;
              if (width > height) {
                if (width > maxWidth) {
                  height *= maxWidth / width;
                  width = maxWidth;
                }
              } else {
                if (height > maxHeight) {
                  width *= maxHeight / height;
                  height = maxHeight;
                }
              }
              canvas.width = width;
              canvas.height = height;

              ctx.drawImage(img, 0, 0, width, height);

              const dataURL = canvas.toDataURL('image/jpeg', 0.7);

              const compressedFile = this.dataURLToFile(dataURL, file.name);

              const fileList = new DataTransfer();
              fileList.items.add(compressedFile);

              this.$refs[`${type}FileInput`].files = fileList.files;
            };
          };
          reader.readAsDataURL(file);
        }
        this[`${type}FileName`] = file.name;
      } else {
        this.$toast.error('Por favor, selecione uma imagem ou um PDF.');
        event.target.value = '';
        this[`${type}FileName`] = null;
      }
    },
    dataURLToFile(dataURL, filename) {
      const arr = dataURL.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    verifyNameFile(name){
      if(name && name.length > 51) {
        const extension = name.split('.').pop();
        const trimmedName = name.slice(0, 48);
        return `${trimmedName}...${extension}`
      }
      return name;
    },
    openFileInput(type) {
      this.$refs[`${type}FileInput`].click();
    },
  },
  computed: {},
  mounted() {},
};
</script>

<style>
</style>
