<template>
  <v-main class="primary h-100" style="height: 100vh;overflow:hidden">
    <!-- <div class="d-flex"> -->
    <v-img class="ajuste" src="../../assets/gif_banca.gif"></v-img>
    <!-- </div> -->
    <!-- Texto de background -->
    <div>
      <div class="d-flex adjustes pt-16 mt-10 px-14 flex-column" style="position: relative;">
        <div class="d-flex justify-center" style="width: 100%; z-index: 11">
          <v-img class="mx-auto mt-16" max-width="150" src="../../assets/banca_etica.png"></v-img>
        </div>
        <v-row class="d-flex justify-center">
          <div style="display: flex !important; justify-content: center !important; z-index: 11">
            <span class="white--text pt-10 text-h6">Acesse sua conta</span>
          </div>
          <v-col cols="12" style="width: 100%; z-index: 11">
            <v-text-field v-on:keyup.enter="submit" type="email" v-model="email" class="rounded"
            placeholder="Usuário" hide-details="auto" outlined style="background-color: white;" dense></v-text-field>
          </v-col>
          <v-col cols="12" style="z-index: 11">
            <v-text-field v-on:keyup.enter="submit"
              type="password"
               class="rounded" style="background-color: white;" placeholder="Senha"
               hide-details="auto" name="password" v-model="password" outlined dense></v-text-field>
          </v-col>
          <v-col cols="12" class="my-0 py-0" style="z-index: 11">
            <v-checkbox dark dense hide-details>
              <template v-slot:label>
                <div>
                  <h5 class="white--text font-weight-regular ">
                    Lembre-se de mim
                  </h5>
                </div>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" style="z-index: 11" class="d-flex justify-center">

            <v-btn rounded block class="pa-3 px-10 transition-fast-in-fast-out" color="secondary" @click="submit">
              <span>Login</span>
            </v-btn>
          </v-col>
          <v-col cols="12" class="d-flex justify-center">
            <span>
              <a class="text-center font-weight-regular white--text" @click="redirectForgotPassword">
                Esqueci minha senha?
              </a>
            </span>
          </v-col>
        </v-row>
      </div>
    </div>

    <footer class="footer">
    </footer>
  </v-main>
</template>
   
<script>

import axios from "axios";
export default {
  data() {
    return {
      errorMessageMap: {
        email: "O campo email é obrigatorio",
        password: "O campo senha é obrigatorio",
      },
      urlEnv: process.env.VUE_APP_SERVICE_URL,
      disabled: false,
      email: null,
      password: null,
      rulesEmail: [
        (v) => this.validateEmail(v) || "E-mail inválido",
      ],
      rules: [
        (v) => !!v || "Campo obrigatório",
        (v) =>
          (v && v.length >= 8) || "A senha deve ter pelo menos 8 caracteres",
        (v) =>
          (v && /[A-Z]/.test(v)) ||
          "A senha deve conter pelo menos uma letra maiúscula",
        (v) =>
          (v && /[a-z]/.test(v)) ||
          "A senha deve conter pelo menos uma letra minúscula",
        (v) =>
          (v && /\d/.test(v)) || "A senha deve conter pelo menos um número",
      ],
    }
  },
  methods: {
    validateEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    submit() {
      this.disabled = true;
      const fields = ["email", "password"];
      for (const field of fields) {
        if (!this[field]) {
          this.$toast.error(this.errorMessageMap[field]);
          this.disabled = false;
          return;
        }
      }

      axios
        .post(this.urlEnv + 'login', {
          email: this.email,
          password: this.password,
        })
        .then((response) => {
          localStorage.setItem('token', response.data.data.token);
          // Recuperar o token do localStorage
          const token = localStorage.getItem('token');
          const config = {
            headers: { Authorization: `Bearer ${token}` },
          };
          axios
            .get(this.urlEnv + `checkCadastro`, config)
            .then((response) => {
              const redirectRoute = localStorage.getItem('redirectRoute');
              if (redirectRoute) {
                this.$router.push(redirectRoute);
                localStorage.removeItem('redirectRoute');
              } else {
                if (response?.data.status == false) {
                  this.$router.replace('/mobile/RegisterHome');
                } else {
                  this.$router.replace('/mobile/HomeProfile');
                  this.$toast.success("Logado com sucesso")
                }
              }
            })
            .catch((error) => {
              console.log(error);
              this.disabled = false;
              false;
            })
        })
        .catch((err) => {
            this.disabled = false;
            if(err.response.data && err.response.data?.data?.error && err.response.data?.data?.error?.email[0])
              this.$toast.error(err.response.data.data.error.email[0]);
            else
              this.$toast.error('Ocorreu um erro inesperado');
        });
        },
    redirectForgotPassword() {
      this.$router.push("/mobile/forgotPasswordMobile")
    }
  }
}
</script>
   
<style scoped>
.ajuste {
  position: absolute;
  z-index: 1;
  top: -30%;
  right: -20%;
  bottom: 35%;
  width: 80%;
  height: auto;
  object-fit: contain;
}

.footer {
  background-color: #DF6E8B;
  /* Cor de fundo do footer */
  color: white;
  /* Cor do texto do footer */
  padding: 8px;
  /* Espaçamento interno do footer */
  width: 100%;
  position: fixed;
  /* Fixa o footer na parte inferior da página */
  bottom: 0;
  /* Posiciona o footer no final da página */
  left: 0;
  /* Alinha o footer à esquerda */
  z-index: 9999;
}
</style>

<style>
.v-application .adjustes {
  background-color: #00265b !important;
}
</style>