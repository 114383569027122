<template>
    <div>
        <layout>
            <template v-slot:content>
                <div class="mt-lg-4 mx-4 mt-xl-12 px-xl-10">
                    <v-row class="d-flex justify-center items-center">
                            <v-card class="pa-8 mt-16" style="width: 800px;">
                            <v-col class="px-lg-16 pt-lg-10" cols="12">
                                <v-row class="text-center">
                                    <v-col cols="12">     
                                        <p class="text-start ml-16 pl-5 text-h4 primary--text">
                                            Digite a nova senha
                                        </p>
                                    </v-col>
                                </v-row>
                                <v-row class="d-flex justify-center">
                                    <v-col cols="8" class="d-flex justify-center">
                                        <span class="text-left blue-grey--text text--darken-1">
                                            <p class="mb-2 primary--text">
                                                Digite sua nova senha. Lembre-se de que ela deve ter:
                                            </p>
                                            <ul>
                                                <li class="text-left primary--text">Um minimo de 8 caracteres</li>
                                                <li class="text-left primary--text">Incluir pelo menos um caractere especial</li>
                                                <li class="text-left primary--text">Conter uma letra maiúscula</li>
                                            </ul>
                                        </span>
                                    </v-col>
    
                                        <v-col cols="7">
                                            <v-text-field v-on:keyup.enter="submit" :append-icon="showPass1 ? 'mdi-eye' : 'mdi-eye-off'"
                                                @click:append="showPass1 = !showPass1" :type="showPass1 ? 'text' : 'password'" class="rounded-lg w-80"
                                                label="Digite sua senha atual" :rules="rulesCurrent" hide-details="auto" name="currentPassword" v-model="currentPassword" outlined
                                                dense>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="7">
                                            <v-text-field v-on:keyup.enter="submit" :append-icon="showPass2 ? 'mdi-eye' : 'mdi-eye-off'"
                                                @click:append="showPass2 = !showPass2" :type="showPass2 ? 'text' : 'password'" class="rounded-lg"
                                                label="Digite sua nova senha" :rules="rules" hide-details="auto" name="password" v-model="password" outlined
                                                dense>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="7">
                                            <v-text-field v-on:keyup.enter="submit" :append-icon="showPass3 ? 'mdi-eye' : 'mdi-eye-off'"
                                                @click:append="showPass3 = !showPass3" :type="showPass3 ? 'text' : 'password'" class="rounded-lg"
                                                label="Digite novamente sua senha" :rules="rulesConfirm" hide-details="auto" name="confirmPassword" v-model="confirmPassword" outlined
                                                dense>
                                            </v-text-field>
                                        </v-col>
    
                                </v-row>
                                <v-row class="d-flex justify-center">
                                    <v-col cols="7" class="d-flex justify-center">
                                        <v-hover v-slot="{ hover }">
                                            <v-btn rounded block :class="{ 'on-hover': hover }"
                                                class="pa-6 px-16 transition-fast-in-fast-out"
                                                :color="hover ? 'secondary' : 'primary'" @click="submit">
                                                <span>Atualização</span>
                                            </v-btn>
                                        </v-hover>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-card>
                        </v-row>
                </div>

            </template>
        </layout>
    </div>
</template>

<script>
import layout from "../../layouts/internalLayout.vue";
import axios from "axios";
export default {
    components: {
        layout,
    },
    data() {
        return {
            errorMessageMap: {
                password: "O campo senha é obrigatorio",
                confirmPassword: "O campo confirmação de senha é obrigatório"
            },
            token: localStorage.getItem("token"),
            urlEnv: process.env.VUE_APP_SERVICE_URL,
            password: null,
            confirmPassword: null,
            currentPassword: null,
            showPass1: false,
            showPass2: false,
            showPass3: false,
            rules: [
                (v) => !!v || "Campo obrigatório",
                (v) =>
                    (v && v.length >= 8) || "A senha deve ter pelo menos 8 caracteres",
                (v) =>
                    (v && /[A-Z]/.test(v)) ||
                    "A senha deve conter pelo menos uma letra maiúscula",
                (v) =>
                    (v && /[a-z]/.test(v)) ||
                    "A senha deve conter pelo menos uma letra minúscula",
                (v) =>
                    (v && /\d/.test(v)) || "A senha deve conter pelo menos um número",
            ],
            rulesCurrent: [
                (v) => !!v || "Campo obrigatório",
            ],
            rulesConfirm: [
                (v) => !!v || "Campo obrigatório",
                (v) => v === this.password || "As senhas não coincidem"
            ]
        };
    },
    methods: {
        submit() {
            const config = {
                headers: { Authorization: `Bearer ${this.token}` },
            };
            const fields = ["password", "confirmPassword"];
            let hasError = false;
            for (const field of fields) {
                if (!this[field]) {
                    this.$toast.error(this.errorMessageMap[field]);
                    hasError = true;
                    break;
                }
            }
            if(hasError) return;

            const passwordValid = this.rules.every(rule => rule(this.password) === true);
            const confirmPasswordValid = this.rulesConfirm.every(rule => rule(this.confirmPassword) === true);
            const currentPasswordValid = this.rulesCurrent.every(rule => rule(this.currentPassword) === true);

            if (!passwordValid || !confirmPasswordValid || !currentPasswordValid) {
                this.$toast.error("Por favor, corrija os erros nos campos de senha.");
                return;
            }

            axios
                .post(this.urlEnv + 'updatePassword', {
                    current_password: this.currentPassword,
                    password: this.password,
                    password_confirmation: this.confirmPassword
                }, config)
                .then((response) => {
                    this.$toast.success(response.data.mensagem);
                })
                .catch((err) => {
                    if (err.response && err.response.data && err.response.data.mensagem) {
                        this.$toast.error(err.response.data.mensagem);
                    } else {
                        console.error(err);
                        this.$toast.error("Erro ao alterar senha");
                    }
                });
        },
    },
    mounted() {
        // this.getUserData();
    },
};
</script>

<style scoped>
.v-navigation-drawer__border {
    display: none;
}

.rounded-card {
    border-radius: 20px;
    border: 1px solid #032a5a
}

.rounded-card-2 {
    border-radius: 20px;
    border: 1px solid #DF6E8B
}

.rounded-card-3 {
    border-radius: 20px;
    border: 1px solid #14aa95;
}

.opacityButton {
    background: rgba(255, 255, 255, 0.226);
}

.oi {
    border-bottom: 10px solid black;
}

.text-h4,
.text-xl-h4,
.text-xl-h5,
.text-h3,
.text-h5 .text-h6,
.white--text,
.text-center,
.d-flex {
    font-family: 'Raleway', cursive !important;
}

@media (min-width: 2560px) {
    .textHome {
        font-size: 24px;
    }
}

@media (min-width: 1920px) and (max-width: 2559px) {
    .textHome {
        font-size: 17px !important;
    }
}

@media (min-width: 1280px) and (max-width: 1919px) {
    .textHome {
        font-size: 10.5px;
    }
}

@media (max-width: 1279px) {
    .textHome {
        font-size: 12px;
    }
}
</style>