<template>
  <div >
      
      <v-row  class="pt-6 px-5">
        <v-col cols="12" md="4">
          <v-text-field
          v-mask="'###.###.###-##'"
            label="CPF *"
            v-model="titularUser.cpf"
            disabled
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" >
          <v-select
            v-model="titularUser.estado_civil"
            :items="estadoCivil"
            item-text="nome"
            item-value="codigo"
            :label="`${$t('marital_status')}`"
            disabled
            outlined
            dense
            hide-details
          ></v-select>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            :label="`${$t('date_of_birth')}`"
            v-model="titularUser.data_nascimento"
            :value="formatDate(birth)"
            type="date"
            disabled
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
          v-mask="'##.###.###-#'"
            label="Documento de identificação"
            v-model="titularUser.identidade"
            disabled
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3" >
          <v-text-field
            type="date"
            label="Data emissão do documento"
            v-model="titularUser.data_emissao_identidade"
            :value="formatDate(emissaoDoc)"
            disabled
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2" >
          <v-text-field
            label="Órgão"
            v-model="titularUser.orgao_expeditor"
            disabled
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field
            label="Uf"
            v-model="titularUser.uf_orgao_expeditor"
            disabled
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="3" class="pa-2">
          <v-select
            :label="`${$t('gender')} *`"
            v-model="titularUser.sexo"
            :items="genderItens"
            item-text="nome"
            item-value="codigo"
            disabled
            outlined
            dense
            hide-details
          ></v-select>
        </v-col>
        <v-col cols="12" md="4" class="pa-2">
          <v-text-field
            :label="`${$t('birthplace')} *`"
            v-model="titularUser.naturalidade"
            disabled
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" class="pa-2">
          <v-text-field
            :label="`${$t('nationality')} *`"
            v-model="titularUser.nacionalidade"
            disabled
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>
        
        <v-col cols="12" md="6">
          <v-text-field
            :label="`${$t('mothers_name')} *`"
            v-model="titularUser.nome_mae"
            disabled
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>
        <!-- <v-col cols="12" md="4">
          <v-text-field
            :label="`${$t('pep')} *`"
            v-model="pessoaPoliticaExposta"
            disabled
            outlined
            dense
          ></v-text-field>
        </v-col> -->
      </v-row>
    <v-col cols="12" class="d-flex justify-center">
      <div class="blue rounded-lg lighten-4 flex-grow-1 d-flex justify-center">
      <span class="font-weight-regular ml-md-8 ml-3 primary--text">{{ $t('To change the fields on this screen, please contact us at the following email address') }}: suporte@belatbrasil.com</span>
      </div>
    </v-col>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      emissaoDoc: null,
      expeditor: null,
      uf: null,
      birthplace: null,
      nationality: null,
      birth: null,
      marital_status: null,
      gender: null,
      titularUser: {},
      titular: {},
      userId: localStorage.getItem("userId"),
      password: null,
      showPass1: false,
      confirmPass1: false,
      token: localStorage.getItem("token"),
      urlEnv: process.env.VUE_APP_SERVICE_URL,
      user: {
        name: null,
        email: null,
        phone_number: null,
        profile_photo_url: null,
      },
      genderItens: [{ codigo: null, nome: null }],
      estadoCivil: [{ codigo: null, nome: null }],
      rules: [
        (v) => !!v || "Campo obrigatório",
        (v) =>
          (v && v.length >= 8) || "A senha deve ter pelo menos 8 caracteres",
        (v) =>
          (v && /[A-Z]/.test(v)) ||
          "A senha deve conter pelo menos uma letra maiúscula",
        (v) =>
          (v && /[a-z]/.test(v)) ||
          "A senha deve conter pelo menos uma letra minúscula",
        (v) =>
          (v && /\d/.test(v)) || "A senha deve conter pelo menos um número",
      ],
      rulesEmail: [(v) => this.validateEmail(v) || "E-mail inválido"],
    };
  },
  methods: {
    formatDate(date) {
      if (date instanceof Date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${day}/${month}/${year}`;
      }
      return date;
    },
    validateEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    async getUserData() {
      const config = {
        headers: { Authorization: `Bearer ${this.token}` },
      };
      try {
        const response = await axios.get(this.urlEnv + "user", config);
        const userData = response.data;
        this.user.name = userData.name;
        this.user.email = userData.email;
        this.user.phone_number = userData.phone_number;
        this.user.profile_photo_url = userData.profile_photo_url;
      } catch (error) {
        console.error(error);
      }
    },
    async getEstadosCivis() {
      const config = {
        headers: { Authorization: `Bearer ${this.token}` },
      };
      try {
        const response = await axios.get(
          this.urlEnv + "getEstadosCivis",
          config
        );
        this.estadoCivil = response.data.data.estados_civis;
      } catch (error) {
        console.error(error);
      }
    },
    async getSexos() {
      const config = {
        headers: { Authorization: `Bearer ${this.token}` },
      };
      try {
        const response = await axios.get(this.urlEnv + "getSexos", config);
        this.genderItens = response.data.data.sexos;
      } catch (error) {
        console.error(error);
      }
    },
    confirmUser() {
      const userData = {
        name: this.user.name,
        phone_number: this.user.phone_number,
        email: this.user.email,
        password: this.password,
      };
      axios
        .post(this.urlEnv + "createLogin", userData)
        .then((response) => {
          console.log(response);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getMeusDados() {
      const config = {
        headers: { Authorization: `Bearer ${this.token}` },
      };
      try {
        axios
          .get(this.urlEnv + `getMeusDados`, config)
          .then((response) => {
            this.titularUser = response.data.data.dados_pessoais
            console.log(this.titularUser)
          })
          .catch((error) => {
            console.error(error);
          });
      } catch(err) {
        console.log(err)
      }
    },
  },
  computed: {
    // pessoaPoliticaExposta() {
    //   return this.titular.pessoa_politicamente_exposta_id === 1 ? 'Sim' : 'Não';
    // },
  },
  mounted() {
    this.getUserData();
    this.getMeusDados();
    this.getSexos();
    this.getEstadosCivis();
  },
};
</script>


<style>
</style>