<template>
    <layout :click-busi="false">
        <template v-slot:content>
            <v-container style="padding: 10px; border: 0px" class="mt-2 custom-container" fluid>
                <!-- <v-dialog max-width="400" v-model="dialog">
          <SimulationInvest @close="close" :negocio="negocio" :titularUser="titularUser"/>
        </v-dialog> -->
                <h3 class="pa-1 ml-5 mb-5 text-h5 primary--text">Invista com consciência.</h3>

                <v-row class="d-flex justify-end" style="margin-bottom: 0.3rem">
                    <v-col cols="11" class="pa-1">
                        <vueSelect :businesses="businesses" :areasDeInvestimento="areasDeInvestimento"
                            @filtrarBusinesses="filtrarBusinesses" />

                    </v-col>
                </v-row>
                <v-row>
                    <v-col v-for="business in filteredBusinesses" :key="business.id" cols="12" sm="6" md="4" lg="3">
                        <!-- :src="business.fotos[0]['content']" -->
                        <v-card @click="getBusinessDetail(business.id)" style="border: 1px solid rgba(20, 170, 149, 0.5); border-radius: 3%;"
                            class="mx-auto pa-1" max-width="350" outlined>
                            <v-img height="250" contain class="align-end text-white"
                                v-bind:src="getPhoto(business.fotos)" :gradient="getGradient(business.neg_area_id)">
                                <v-card-title style="padding: 5px 16px">
                                    <span class="white--text font-weight-bold">
                                        {{ getNegAreaDescription(business.neg_area_id) }}
                                    </span>
                                </v-card-title>
                            </v-img>

                            <v-card-title style="padding: 5px 16px">{{business.nome}}</v-card-title>

                            <v-card-text>
                                <v-progress-linear background-color="grey lighten-3"
                                    :color="getProgressBarColor(business.neg_area_id)"
                                    :value="calcPercentInvestiment(business)" height="15" rounded
                                    :style="{ fontSize: '10px', borderRadius: '8px', border: '1px solid #ccc' }">
                                    <template v-slot:default="{ value }">
                                        <span :class="{ 'white--text': value > 50 }">
                                            {{ Math.ceil(value) }}%
                                        </span>
                                    </template>
                                </v-progress-linear>
                                <div class="text-center mt-2">
                                    <span class="secondary--text mt-xl-1 text-caption text-uppercase font-weight-bold"
                                        style="font-size: 10px !important;">Valor total do empréstimo</span>
                                </div>
                                <v-col class="ml-1 mr-2 pa-0 grey lighten-3 rounded text-center">
                                    <span class="font-aleo primary--text  font-weight-light"
                                        style="font-size: 16px; padding-top: 0;">
                                        {{ numberToReal(Math.floor(parseFloat(business.valor_a_ser_captado) / 1000) *
        1000) }}
                                    </span>
                                </v-col>
                                <v-row class="pb-0 px-1 pt-3">
                                    <v-col cols="6" class="py-0 px-2">
                                        <div class="secondary--text text-caption text-uppercase font-weight-bold text-center mt-2"
                                            style="font-size: 9px !important;">
                                            Taxa de retorno
                                        </div>

                                    </v-col>
                                    <v-col cols="6" class="py-0 px-2">
                                        <div class="secondary--text text-caption text-uppercase font-weight-bold text-center mt-2"
                                            style="font-size: 9px !important;">
                                            Prazo de operação
                                        </div>

                                    </v-col>
                                </v-row>
                                <v-row class="pa-0">
                                    <v-col
                                        class="mr-1 ml-2 pa-0 grey lighten-3 rounded d-flex align-center text-center justify-center">
                                        <span class="font-aleo primary--text  font-weight-light"
                                            style="font-size: 16px; padding-top: 0;">{{
        numberToPercentage(parseFloat(business.taxa)) }} <br>
                                            <span class="texto2 primary--text font-weight-medium"
                                                style="padding: 0 !important;">NOMINAL MENSAL</span>
                                        </span>

                                    </v-col>
                                    <v-col
                                        class="ml-1 mr-2 pa-0 grey lighten-3 rounded d-flex align-center text-center justify-center">
                                        <span class="font-aleo primary--text align-center font-weight-light"
                                            style="font-size: 16px; padding-top: 0;">{{
                                            business.prazo }} MESES</span>
                                        <!--<v-card-text class="texto2 red--text font-weight-medium pb-xl-2 text-center" style="padding: 0">RENOVABLE</v-card-text>-->
                                    </v-col>
                                </v-row>
                                <!-- <v-row>
                    <v-col> {{ business.numero_parcelas }} {{ $t('installments') }} </v-col>
                    <v-col class="text-right">
                      {{ business.taxa }}% a.m.
                    </v-col>
                  </v-row> -->
                                <div class="text-center mt-4">
                                    <span class="secondary--text mt-xl-1 text-caption text-uppercase font-weight-bold"
                                        style="font-size: 10px !important;">Investimento Mínimo</span>
                                </div>
                                <div class="grey lighten-3 rounded align-center text-center" style="padding-top: 0">
                                    <span class="font-aleo primary--text font-weight-light"
                                        style="font-size: 16px; padding-top: 0;">{{
                                        numberToReal(parseFloat(business.ticket_minimo)) }}</span>
                                </div>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-center flex">
                                <v-hover v-slot="{ hover }">
                                    <v-btn @click="getBusinessDetail(business.id)" style="width: 100%"
                                        :class="{ 'on-hover': hover }" class="rounded-xl px-16 transition-fast-in-fast-out"
                                        :color="hover ? 'secondary' : 'primary'">
                                        faça uma simulação
                                    </v-btn>
                                </v-hover>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </layout>
</template>

<script>
import vueSelect from "../Business/Components/SelectVueMobile.vue"
import { numberToReal, getPhoto, numberToPercentage } from "../../js/belatUtils";
import axios from "axios";
import layout from "@/layouts/internalLayoutMobile.vue";
// import SimulationInvest from "./Modal/SimulationInvestMobile.vue";

export default {
    metaInfo() {
        return {
            title: 'BELat - Negócios'
        }
    },
    components: {
        // SimulationInvest,
        layout,
        vueSelect
    },
    data() {
        return {
            negocio: {},
            titularUser: {},
            dialog: false,
            businessOptions: [],
            selectedBusiness: null,
            drawer: false,
            group: null,
            businessId: localStorage.getItem("negocio"),
            token: localStorage.getItem("token"),
            urlEnv: process.env.VUE_APP_SERVICE_URL,
            areasDeInvestimento: [],
            businesses: [],
            filteredBusinesses: [],
            user: {
                id: null,
                name: null,
                email: null,
                phone_number: null,
                profile_photo_url: null,
            },
            userId: localStorage.getItem("userId"),
            items: [
                {
                    title: "Meus Investimentos",
                    icon: "mdi-home-city",
                    route: "/user/MyInvestiments",
                },
                { title: "Meus dados", icon: "mdi-account", route: "/user/MyInfo" },
            ],
        };
    },

    methods: {
       async getBusinessDetail(businessId) {
            try {
        const config = {
          headers: { Authorization: `Bearer ${this.token}` },
        };

        const checkCadastroResponse = await axios.get(
          this.urlEnv + "checkCadastro",
          config
        );

        if (checkCadastroResponse.data.status) {
          const response = await axios.get(
            this.urlEnv+ `businessDetail/${businessId}`,
            config
          );
          console.log(response);
          this.$router.push({
            name: "ViewBusinessMobile",
            params: {
              businessId: businessId,
            },
          });
          localStorage.setItem("negocio", businessId);
        } else {
          this.$router.push({
            name: "TemplateRegisterMobile",
          });
          localStorage.setItem("negocio", businessId);
        }
      } catch (error) {
        console.error(error);
      }
    },
        close() {
      this.dialog = false;
    },
        getNegAreaDescription(neg_area_id) {
            switch (neg_area_id) {
                case 1:
                    return "Desenvolvimento social";
                case 2:
                    return "Educação e cultura";
                case 3:
                    return "Meio ambiente";
                default:
                    return "Área desconhecida"; // Caso de valor não mapeado
            }
        },
        areasDisponiveis() {
            const names = new Set();
            const groupedAreas = this.businesses.reduce((acc, obj) => {
                const name = obj.area?.nome;
                if (name && !names.has(name)) {
                    names.add(name);
                    acc.push({
                        id: obj.area.id,
                        text: name,
                        value: obj.area.id
                    });
                }
                return acc;
            }, []);
            this.areasDeInvestimento = groupedAreas;
        },
        filtrarBusinesses(businesses) {
            this.filteredBusinesses = businesses;
        },
        getProgressBarColor(negAreaId) {
            switch (negAreaId) {
                case 1:
                    return 'desenvolvimento_social';
                case 2:
                    return 'educacao_e_cultura';
                case 3:
                    return 'meio_ambiente';
                default:
                    return 'black';
            }
        },
        getGradient(negAreaId) {
            switch (negAreaId) {
                case 1:
                    return 'to top, #032A5A , transparent 40%';
                case 2:
                    return 'to top, #d64d7a, transparent 40%';
                case 3:
                    return 'to top, #14AA95, transparent 40%';
                default:
                    return 'to top, black, transparent 40%';
            }
        },
        finish() {
            this.$router.push({
                name: "ListView",
            });
        },
        async showMore(businessId) {
            try {
                const config = {
                    headers: { Authorization: `Bearer ${this.token}` },
                };

                const checkCadastroResponse = await axios.get(
                    this.urlEnv + "checkCadastro",
                    config
                );

                if (checkCadastroResponse.data.status) {
                    const response = await axios.get(
                        this.urlEnv + `businessDetail/${businessId}`,
                        config
                    );
                    console.log(response);
                    this.$router.push({
                        name: "ViewBusiness",
                        params: {
                            businessId: businessId,
                        },
                    });
                    localStorage.setItem("negocio", businessId);
                } else {
                    this.$router.push({
                        name: "templateRegister",
                    });
                    localStorage.setItem("negocio", businessId);
                }
            } catch (error) {
                console.error(error);
            }
        },
        // Script
        getListagem() {
            const config = {
                headers: { Authorization: `Bearer ${this.token}` },
            };
            axios.get(this.urlEnv + "listBusiness", config).then((response) => {
                this.businesses = response.data.data.negocios;
                this.filteredBusinesses = response.data.data.negocios;
                this.areasDisponiveis();
                this.businessOptions = [...new Set(this.businesses.filter(function (business) {
                    if (business.neg_area_id !== null)
                        return true;
                    else return false;
                })
                    .map((business) => ({
                        text: business.neg_area_id,
                        value: business.neg_area_id,
                    })))];
                // console.log(this.businesses);
            }).catch(error => {
                console.log(error)
            });
        },
        async getInvestidor() {
      const config = {
        headers: { Authorization: `Bearer ${this.token}` },
      };
      try {
        axios
          .get(this.urlEnv + `getInvestidor`, config)
          .then((response) => {
            this.titularUser = response.data.data.investidor;
          })
          .catch((error) => {
            console.error(error);
          });
      } catch (err) {
        console.log(err);
      }
    },
        calcPercentInvestiment(business) {
            if (business.valor_a_ser_captado && business.valor_ja_contratado)
                return 100 * (business.valor_ja_contratado) / business.valor_a_ser_captado;
            else return 0;
        },
        async getUserData() {
            const config = {
                headers: { Authorization: `Bearer ${this.token}` },
            };
            try {
                const response = await axios.get(this.urlEnv + "user", config);
                const userData = response.data;
                this.user.id = userData.id;
                this.user.name = userData.name;
                this.user.email = userData.email;
                this.user.phone_number = userData.phone_number;
                this.user.profile_photo_url = userData.profile_photo_url;
                localStorage.setItem("userId", userData.id);
            } catch (error) {
                console.error(error);
            }
        },
    },
    watch: {
        // selectedBusiness(newVal) {
        //   if (newVal) {
        //     this.filteredBusinesses = this.businesses.filter(
        //       (business) => business.neg_area_id === newVal
        //     );
        //   } else {
        //     this.filteredBusinesses = this.businesses;
        //   }
        // },
    },

    computed: {
        cantInvest(){
      return parseFloat(this.negocio.valor_ja_contratado) >= parseFloat(this.negocio.valor) || this.negocio.status?.codigo != '02';
    },

        negAreaName() {
            switch (this.business.neg_area_id) {
                case 1:
                    return "Desenvolvimento social";
                case 2:
                    return "Educação e cultura";
                case 3:
                    return "Meio ambiente";
                default:
                    return "";
            }
        },
        // filteredBusinesses() {
        //   if (this.selectedBusiness) {
        //     return this.businesses.filter(
        //       (business) => business.neg_area_id === this.selectedBusiness
        //     );
        //   } else {
        //     return this.businesses;
        //   }
        // },
        // getInvestidor() {
        //   return getInvestidor;
        // },
        getPhoto() {
            return getPhoto;
        },
        numberToReal() {
            return numberToReal;
        },
        numberToPercentage() {
            return numberToPercentage;
        },
    },
    mounted() {

        this.getInvestidor();
        this.getListagem();
        this.getUserData();
        // this.getInvestidor();
    },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Aleo&display=swap');

.font-aleo {
    font-family: 'Aleo', sans-serif !important;
}

.text-h4,
.text-xl-h4,
.text-xl-h5,
.text-h3,
.text-h5 .text-h6,
.white--text,
.text-center,
.secondary--text,
.d-flex {
    font-family: 'Raleway', cursive !important;
}

@media (min-width: 2560px) {}

@media (min-width: 1920px) and (max-width: 2559px) {
    .texto {
        font-size: 0.61rem !important;
    }

    .texto2 {
        font-size: 0.59rem !important;
    }

    .porcentagem {
        font-size: 1.1rem !important
    }

    .mensal {
        font-size: 0.815rem !important
    }
}

@media (min-width: 1280px) and (max-width: 1919px) {
    .v-card__text {
        padding: 7px !important;
    }

    .texto {
        font-size: 0.60rem !important;
    }

    .texto2 {
        font-size: 0.59rem !important;
    }

    .porcentagem {
        font-size: 1.1rem !important
    }

    .mensal {
        font-size: 0.800rem !important
    }
}

@media (max-width: 1279px) {}


.v-input__slot {
    margin: 0 !important
}

.v-card__actions {
    display: none !important;
}

.VueCarousel-navigation-prev {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background: #14AA95 !important;
    left: 2.3rem !important;
    top: 1rem !important;
}

.VueCarousel-navigation-next {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background: #14AA95 !important;
    right: 2.3rem !important;
    top: 1rem !important;
}

.VueCarousel-navigation-button {
    color: white !important;
}
</style>
