<template>
    <div>
        <layout>
            <template v-slot:content>
                <div class="mt-lg-4 mx-4 mt-xl-12 px-xl-10">
                    <v-row>
                        <v-col class="px-lg-16 pt-lg-10" cols="12">
                            <h1 class="font-weight-bold  primary--text">
                                Configurações
                            </h1>
                            <div class="mt-10 mt-xl-16" style="display: flex; justify-content: space-between;">
                                <div>
                                    <h1 class="primary--text" style="font-weight: 500; font-size:16px ">SENHA</h1>
                                    <span class="primary--text" style="font-size:13px ">Alterar sua senha de acesso</span>
                                </div>
                                <div>
                                    <v-btn class="px-6" @click="redirectTo('ChangePasswordMobile')" color="primary" rounded>Alterar senha</v-btn>
                                </div>
                            </div>
                            <div class="mt-10 mt-xl-16" style="display: flex; justify-content: space-between;">
                                <div>
                                    <h1 class="primary--text" style="font-weight: 500;font-size:15px ;text-transform: uppercase;">Documento de identificação</h1>
                                    <span class="primary--text" style="font-size:13px ">Atualize seu documento de identificação</span>
                                </div>
                                <div>
                                    <v-btn class="px-6" @click="navigateTo('meus-documentos')" color="primary" rounded>Editar</v-btn>
                                </div>
                            </div>
                            <!-- <div class="mt-10 mt-xl-16" style="display: flex; justify-content: space-between;">
                                <div>
                                    <h1 class="text-lg-h6 black--text">Idioma</h1>
                                    <span class="primary--text">Selecione seu idioma preferido</span>
                                </div>
                                <div>
                                    <v-btn class="px-6" disabled color="primary" rounded>Portugues</v-btn>
                                </div>
                            </div>
                            <div class="mt-10 mt-xl-16" style="display: flex; justify-content: space-between;">
                                <div>
                                    <h1 class="text-lg-h6 black--text">Manter sessão</h1>
                                    <span class="primary--text">Impedir o logout automático</span>
                                </div>
                                <div>
                                    <toggle-button v-model="showOptions" :value="showOptions" color="#00265b"
                            :labels="{ checked: 'Sim', unchecked: 'Não' }" />
                                </div>
                            </div>
                            <div class="mt-10 mt-xl-16" style="display: flex; justify-content: space-between;">
                                <div>
                                    <h1 class="text-lg-h6 black--text">Modo escuro</h1>
                                    <span class="primary--text">Alterar a cor do plano de fundo</span>
                                </div>
                                <div>
                                    <toggle-button v-model="showOptions" :value="showOptions" color="#00265b"
                                    :labels="{ checked: 'Sim', unchecked: 'Não' }" />
                                </div>
                            </div> -->
                        </v-col>
                    </v-row>
                </div>

            </template>
        </layout>
    </div>
</template>

<script>
import layout from "../../layouts/internalLayoutMobile.vue";
import axios from "axios";
import { redirectTo } from "../../js/belatUtils";
export default {
    metaInfo() {
    return {
      title: 'BELat - Configurações'
    }
  },
    components: {
        layout,
    },
    data() {
        return {
            tabs: null,
            tabNames: ['Dados pessoais', 'Informações adicionais', 'Declarações'],
            text: '',
            userId: localStorage.getItem("userId"),
            user: {
                name: null,
                email: null,
                phone_number: null,
                profile_photo_url: null,
            },
            currentScreenIndex: 0,
            token: localStorage.getItem("token"),
            urlEnv: process.env.VUE_APP_SERVICE_URL,
            items: [
                {
                    title: "Meus Investimentos",
                    icon: "mdi-home-city",
                    route: "/user/MyInvestiments",
                },
                { title: "Meus dados", icon: "mdi-account", route: "/user/MyInfo" },
            ],
            right: null,
        };
    },
    methods: {
        navigateTo(section) {
      this.$router.push({ name: 'InfoPage', params: { section } });
    },
        async getUserData() {
            const config = {
                headers: { Authorization: `Bearer ${this.token}` },
            };
            axios.get(this.urlEnv + "user", config).then(response => {
                // if(response.)
                console.log(response)
                let userData = response.data;
                this.user.name = userData.name;
                this.user.email = userData.email;
                this.user.phone_number = userData.phone_number;
                this.user.profile_photo_url = userData.profile_photo_url;
                console.log(userData);
            }).catch(error => {
                console.error(error);
            })
        },
        setCurrentScreen(index) {
            this.currentScreenIndex = index;
        },
        redirectTo(route) {
            redirectTo(this.$router, route);
        }
    },
    mounted() {
        this.getUserData();
    },
    computed: {
        currentScreen() {
            return this.screens[this.currentScreenIndex];
        },
        totalScreens() {
            return this.screens.length;
        },
        isMobile() {
            return this.$vuetify.breakpoint.xsOnly;
        },
    },
};
</script>

<style scoped>
.v-navigation-drawer__border {
    display: none;
}

.rounded-card {
    border-radius: 20px;
    border: 1px solid #032a5a
}

.rounded-card-2 {
    border-radius: 20px;
    border: 1px solid #DF6E8B
}

.rounded-card-3 {
    border-radius: 20px;
    border: 1px solid #14aa95;
}

.opacityButton {
    background: rgba(255, 255, 255, 0.226);
}

.oi {
    border-bottom: 10px solid black;
}

.text-h4,
.text-xl-h4,
.text-xl-h5,
.text-h3,
.text-h5 .text-h6,
.white--text,
.text-center,
.d-flex {
    font-family: 'Raleway', cursive !important;
}

@media (min-width: 2560px) {
    .textHome {
        font-size: 24px;
    }
}

@media (min-width: 1920px) and (max-width: 2559px) {
    .textHome {
        font-size: 17px !important;
    }
}

@media (min-width: 1280px) and (max-width: 1919px) {
    .textHome {
        font-size: 10.5px;
    }
}

@media (max-width: 1279px) {
    .textHome {
        font-size: 12px;
    }
}
</style>